<div class="main-banner chatbot-main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Build your brand connecting with customers</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Try It Free Now<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image bg-2">
                    <app-chatting></app-chatting>
                    <img src="assets/img/banner-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/shape20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>
</div>

<section class="partner-area pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="partner-title">
                    <h3>Featured by:</h3>
                </div>
            </div>
            <div class="col-lg-9 col-md-12">
                <div class="partner-slides owl-carousel owl-theme">
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img1.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img2.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img4.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Path is here for faster way of connections with your customers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/img2.png" alt="image">
                </div>
            </div>
            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>The best Innovative Chatbot and automations are here to expand</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>The ability to elicit, assessing opportunities</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Simplify Communication</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>Drag and Drop Widgets</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Quick Setup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>Automatise Chatbots</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box" data-wow-delay=".5s">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>Automated Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Machine Learning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme.com</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Jexsa</span></h2>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What access do I have on the free plan? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What access do I have on a free trial? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Does the price go up as my team gets larger? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How can I cancel/pause my subscription? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card mb-0">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I pay via an invoice? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>