<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo-white.png" alt="image"></a>
                    </div>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> -->
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <!-- <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Our Pricing</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div> -->
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a href="https://support.pubq.se" target="_blank">Guider & artiklar</a></li>
                        <li><a routerLink="/policy">Policy</a></li>
                        <li><a routerLink="/terms">Villkor</a></li>                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/ZuwbtTm7Fbca3eVp7" target="_blank">Butängsgatan 32,
                                602 23 Norrköping</a></li>
                        <li>Email: <a href="mailto:info@pubq.se">info@pubq.se</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/pubqplatform" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/28784197" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/pubqplatform/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>