<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Policy</h2>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <span style="font-weight: bold;">Innledning</span>
                <br>
                PubQ AB, 559057-4843 (”Selskapet” eller ”Vi”) er ansvarlig for alle dine personlige data behandlet av
                oss. Sikkerhet er veldig viktig for oss. Vi respekterer personvernet ditt og forplikter oss til å
                beskytte din personlige informasjon i samsvar med denne policyen. Personopplysningene dine behandles med
                støtte fra ditt samtykke. Ved å godta denne policyen godtar du behandlingen av dine personopplysninger i
                samsvar med følgende.
                <br>
                <br>
                <span style="font-weight: bold;">Ansvarlig for personopplysninger</span>
                <br>
                Selskapet er ansvarlig for dine personopplysninger, som vi behandler og håndterer i samsvar med
                gjeldende lovgivning. Kontaktinformasjonen vår finner du i punkt 9.
                Hensikt. Formålet med denne personopplysningspolicyen er å forklare hvordan selskapet behandler,
                oppbevarer, lagrer, administrerer, beskytter og deler informasjon om deg gjennom PubQs
                mobilapplikasjoner som du laster ned for bruk i mobile enheter (”applikasjonen”). Les denne policyen
                nøye. Du er hjertelig velkommen til å kontakte oss i henhold til kontaktinformasjonen som vises under
                punkt 9 for spørsmål eller bekymringer.
                Utstrekning. Denne personopplysningspolitikken gjelder bare for informasjonen som selskapet samler inn
                gjennom applikasjonen, og visse tilknyttede nettsider som gjøres tilgjengelige gjennom applikasjonen
                eller online. Denne policyen gjelder ikke informasjon som er samlet inn av selskapet offline eller
                gjennom andre verktøy. Applikasjonen kan inneholde lenker til tredjeparts nettsteder som ikke eies eller
                kontrolleres av selskapet. Vi ønsker derfor å informere deg om at selskapet ikke er ansvarlig for
                rutinen for og behandling av personopplysninger på slike nettsteder. Vi oppfordrer deg derfor til å være
                oppmerksom på når du forlater applikasjonen og oppfordrer deg til å lese personopplysningspolicyene på
                hver side som samler inn personopplysninger.
                Vilkår for bruk. Vær oppmerksom på at din bruk av applikasjonen dekkes av lisensavtalen for sluttbrukere
                som er tilgjengelig på www.pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Informasjon som vi samler inn om deg</span>
                <br>
                Vi samler inn informasjon som du gir oss, for eksempel når du oppretter eller oppdaterer kontoen din,
                registrerer deg for å bruke applikasjonen, ber om tjenester fra oss, ber om informasjon fra oss,
                kontakter kundeservice eller på annen måte kommuniserer med oss. Informasjonen kan inneholde navn, hjem-
                og leveringsadresse(r), e-postadresse, mobiltelefonnummer, fødselsdato og kjønn.
                Informasjon vi kan samle inn gjennom din bruk av applikasjonen
                Google Analytics og Firebase Analytics
                Vi bruker Google Analytics og Firebase Analytics, to webanalyseprogrammer levert av Google, Inc.
                (”Google”) for å samle inn viss informasjon om ditt besøk til nettstedet vårt, www.pubq.se. Denne
                informasjonen kan omfatte: detaljer om ditt besøk til nettstedet, stedsinformasjon, sider du besøker og
                informasjon om din mobile enhet og internettforbindelse, inkludert IP-adresse, operativsystem og
                nettleser.
                <br>
                <br>
                <span style="font-weight: bold;">Informasjonskapsler (Cookies) </span>
                <br>
                Vi bruker informasjonskapsler (Cookies) og annen teknologi for å motta og lagre visse typer informasjon
                når du kommuniserer med applikasjonen vår via mobilenheten din. Alle som besøker et nettsted eller en
                applikasjon med informasjonskapsler, må i henhold til lov om elektronisk kommunikasjon (§ 2-7b) motta
                informasjon om dem og bevisst godkjenne bruken av dem. En informasjonskapsel er en liten tekstfil som
                lagres på den besøkendes datamaskin. Filen inneholder en serie tegn som sendes til mobilenheten din når
                du besøker et nettsted eller bruker et program. Informasjonskapsler kan lagre unike funksjoner, bruke
                preferanser og annen informasjon. Vi bruker informasjonskapsler for å forbedre kvaliteten på tjenestene
                våre, inkludert for å lagre brukerinnstillinger og for å spore sluttbrukere.
                <br>
                <br>
                Tredjeparts informasjonskapsler (Cookies)
                <br>

                Informasjonskapsler (Cookies) som brukes til å samle inn informasjon for skreddersydd innhold og for
                nettstatistikk som kommer fra noen andre enn oss som er ansvarlige for applikasjonen, kan være såkalte
                tredjeparts informasjonskapsler. Vi bruker Google Analytics som bruker informasjonskapsler i form av
                tekstfiler lagret på datamaskinen din, for å hjelpe oss med å analysere hvordan du bruker nettstedet og
                applikasjonen. Du kan lære mer om hvordan Google bruker data når du besøker nettstedet vårt, ved å gå
                til ”Hvordan Google bruker data når vi bruker partnernes nettsteder eller apper” tilgjengelig på
                www.google.com/policies/privacy/partners/ ).
                <br>
                <br>
                Stedsdata
                <br>
                Vi kan også automatisk samle inn stedsdata via mobilenheten din. Denne informasjonen kan brukes til å
                forbedre kvaliteten på tjenestene våre, for å lede deg til tjenester i ditt område eller for å samle inn
                data på aktivitetsnivået ditt. Du kan aktivere og deaktivere denne funksjonen via innstillingene til
                mobilenheten din.
                <br>
                <br>
                Andre datainnsamlingsteknologier
                <br>
                Applikasjonen kan også samle inn data gjennom Firebase-analyse, en mobilanalyseapplikasjon, for å logge
                datamaskinkrasj og andre «non-fatal» som kan oppstå når du bruker applikasjonen. Informasjonen som
                samles inn, kan omfatte hva du gjorde på tidspunktet for krasj eller feilkode, og hvilke søk du gjorde
                når du brukte applikasjonen. Disse dataene kan ikke tilskrives PubQ-kontoen din, men hjelper oss med å
                forbedre applikasjonen.
                <br>
                <br>
                Samtykke til bruk og mulighet til å nekte informasjonskapsler
                <br>
                Ved å bruke applikasjonen godtar du bruk av informasjonskapsler som ovenfor. Det er mulig å si nei til
                informasjonskapsler ved å slette informasjonskapsler fra harddisken, noe som kan gjøres når som helst.
                Slette informasjonskapsler kan gjøre at dine personlige innstillinger forsvinner. Du kan også endre
                innstillingene i nettleseren din slik at den ikke tillater at informasjonskapsler er lagret på
                harddisken din. Dette kan svekke funksjonaliteten til visse websider og kan føre til at noen funksjoner
                og tjenester i applikasjonen slutter å fungere. Hvis nettleseren din støtter en såkalt ”inkognitomodus”,
                kan du aktivere den for automatisk å slette alle informasjonskapsler hver gang du lukker nettleseren.

                <br>
                <br>
                Ingen informasjon fra barn under 18 år
                <br>
                Vår applikasjon er ikke ment for bruk av barn under 18 år. Ingen under 18 år kan legge inn informasjon i
                applikasjonen. Vi samler ikke bevisst informasjon fra personer under 18 år. Hvis du er under 18 år, ikke
                bruk eller skriv inn noe informasjon i applikasjonen. Hvis vi blir klar over at vi har samlet inn eller
                mottatt informasjon fra et barn under 18 år uten foreldrenes tillatelse, vil vi slette informasjonen.
                Hvis du tror vi kan ha informasjon om et barn under 18 år, kan du ringe +46736235298 eller gi oss
                beskjed via support@pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Vi behandler dine personopplysninger for følgende formål</span>
                <br>
                Å tilby enheter, tjenester og informasjon. Vi samler inn informasjon fra deg for å oppfylle våre
                kontraktsforpliktelser overfor deg, og for å levere enheter og tjenester du ber om når du bruker
                applikasjonen, og for å gi informasjon du ber om fra oss. For dette formålet bruker vi informasjonen din
                til å kontakte deg angående dine bestillinger og til å sende bestilte enheter til deg. Vi kan sende deg
                nyhetsbrev, informasjon om enheter og våre tjenester, nye tilbud og/eller annet markedsføringsmateriell.
                Vi kan også bruke informasjonen din til å administrere undersøkelser vi gjennomfører for å forstå
                brukernes behov.
                Reklame. Vi bruker ikke aktiviteten din i applikasjonen til å vise annonser for PubQ eller andre
                tredjeparter. Vi tilhører ikke noe annonsenettverk som bruker nettleserloggen din på andre nettsteder
                for å vise interessebaserte annonser, og vi bruker ikke informasjonskapselteknologi som er designet for
                å spore deg etter at du forlater applikasjonen.
                Ditt samtykke. Når vi har ditt samtykke, kan vi gi informasjon til andre selskaper, organisasjoner eller
                personer utenfor selskapet. Du kan når som helst varsle oss om at du ønsker å tilbakekalle dette
                samtykke. I et slikt tilfelle vil ingen viderebehandling av personopplysninger om deg finne sted.
                <br>
                <br>
                <span style="font-weight: bold;">Hvem vi deler din informasjon med</span>
                <br>
                For å oppfylle formålene som informasjonen ble samlet inn for, kan informasjonen din registreres,
                lagres, brukes og overføres til tredjepartsleverandører til oss som angitt nedenfor.
                Tredjepartsleverandører. Vi kan gi informasjon til autoriserte tredjeparts tjenesteleverandører, slik at
                tjenesteleverandøren kan administrere serverne som brukes til applikasjonen, for å håndtere
                bestillinger, bestille og levere varer og for å levere tjenester du ber om fra oss. Vi kan dele
                informasjonen din med tredjeparts tjenesteleverandører for å gi støtte via f.eks. telefonen. Disse
                tjenesteleverandørene vil ha tilgang til informasjonen din for å tilby tjenestene du ber om. Når de har
                tilgang til informasjonen din, bruker vi kontraktsmessig og teknisk beskyttelse for å begrense
                leverandørens bruk av informasjonen de har tilgang til, for å hjelpe oss med å tilby tjenesten.
                Juridisk prosedyre. Vi vil dele personlig informasjon med tredjepartsbedrifter, organisasjoner eller
                enkeltpersoner utenfor selskapet hvis vi har grunn til å tro at behandlingen, håndteringen eller
                publiseringen av informasjonen er nødvendig for å: a) overholde alle gjeldende lover, forskrifter,
                rettslige prosedyrer eller håndhevbare myndighetsbeslutninger; (b) håndheve gjeldende bruksvilkår,
                inkludert etterforskning av potensielle overtredelser; (c) oppdage, forhindre eller på annen måte
                håndtere svindel, sikkerhets- eller tekniske problemer; eller (d) Beskytte selskapet mot skade på
                rettigheter, eiendom eller sikkerhet, skade på våre brukere, kunder eller publikum som påkrevd eller
                tillatt i loven.
                Overføring i tilfelle salg eller endring av kontroll. Hvis eierskapet til virksomheten vår endres, eller
                vi på annen måte overfører eiendeler knyttet til vår virksomhet eller søknaden til en ny eier, kan vi
                overføre informasjonen din til den nye eieren hvis formålet med den innsamlede informasjonen er den
                samme med den nye eieren som i denne personopplysningspolicyen. Du vil bli informert om hvem som er
                ansvarlig for dine personopplysninger hvis disse blir overført til ny eier. I tilfelle overføring til ny
                eier, gjelder denne personopplysningspolicyen for din informasjon, dersom du ikke godkjenner en ny
                personopplysningspolicy.
                Samlet informasjon. Vi kan, uten begrensning, avsløre samlet informasjon om brukerne våre og annen
                informasjon som ikke identifiserer noe enkeltindivid.
                <br>
                <br>
                <span style="font-weight: bold;">Dine rettigheter og tilgang til din informasjon</span>
                <br>
                Vi er ansvarlige for å sikre at dine personopplysninger blir behandlet i samsvar med gjeldende lov. Du
                kan når som helst be om å få korrigert, avidentifisert, supplert eller slettet din personlige
                informasjon som er feil, ufullstendig eller misvisende. Vi kan også gjøre dette på eget initiativ.
                Du har rett til å be om tilgang til og korrigere eller slette personopplysningene fra oss, eller å be om
                begrensning av behandlingen av personopplysningene som gjelder deg og retten til å motsette deg
                behandlingen. Du har også rett til å sende inn klager til Datatilsynet. Du kan få tilgang til og
                oppdatere viss informasjon som vi har angående kontoen din ved å logge på kontoen din og gå til
                profilseksjonen i applikasjonen vår. Hvis du har spørsmål om informasjon som vi har om deg eller trenger
                å oppdatere informasjonen din, kan du kontakte oss på telefon, e-post eller brev i henhold til
                kontaktinformasjonen nedenfor. Du kan velge bort markedsføring gjennom applikasjonen ved å bruke
                funksjonen til å velge bort eller deaktivere e-post.
                Du kan avslutte kontoen din ved å kontakte oss via e-post eller telefon i henhold til
                kontaktinformasjonen nedenfor under 9, og be om at vi avslutter kontoen din. Hvis du avslutter kontoen
                din, vil vi ikke lenger bruke informasjonen din og ikke dele den med tredjeparter.
                Du har rett til å be om tilgang til dine personlige data og motta informasjon, inkludert hvilke
                personlige data som er lagret hos oss, formålene med behandlingen av disse personopplysningene,
                mottakerne eller kategorier av mottakere som personopplysningene har eller må utleveres til, den
                tiltenkte perioden eller kriteriene for å bestemme perioden personopplysningene skal lagres i.
                I tilfelle du ønsker å overføre personopplysningene du har oppgitt for å kunne bruke dem andre steder
                (dataportabilitet), kan du kontakte PubQ.
                <br>
                <br>
                <span style="font-weight: bold;">Hvor lenge vi behandler dataene dine</span>
                <br>
                Dine personopplysninger blir bare lagret så lenge det er behov for å oppbevare dem, for å oppfylle
                formålene som dataene ble samlet inn i samsvar med denne personopplysningspolicyen.
                <br>
                <br>
                <span style="font-weight: bold;">Sikkerhet rundt din informasjon</span>
                <br>
                Vi bruker fysiske, tekniske og administrative sikkerhetstiltak og bransjespesifikke garantier for å
                beskytte personvernet og sikkerheten din. Siden Internett ikke er et 100% sikkert miljø, kan vi ikke
                garantere sikkerheten til informasjonen du overfører til oss. Det er ingen garanti for at informasjon
                ikke blir tilgjengelig, avslørt, endret eller ødelagt ved brudd på noen av våre fysiske eller tekniske
                beskyttelsestiltak. Det er ditt ansvar å beskytte påloggingsinformasjonen din.
                <br>
                <br>
                <span style="font-weight: bold;">Endringer i vår personopplysningspolicy</span>
                <br>
                Vår personopplysningspolicy kan endres uten varsel. Vi vil ikke svekke rettighetene dine under denne
                personopplysningspolicyen uten ditt samtykke eller endre dem i strid med gjeldende lov. Vi vil publisere
                alle endringer i personopplysningspolicyen på nettstedet vårt www.pubq.se. Hvis endringene er
                vesentlige, vil vi varsle deg gjennom et spesielt varsel. Vi vil også oppbevare tidligere versjoner av
                denne personvernerklæringen i et arkiv for gjennomgang. Din fortsatte bruk av applikasjonen etter at vi
                har gjort endringer, anses å utgjøre aksept for disse endringene.
                <br>
                <br>
                <span style="font-weight: bold;">Kontaktinformasjon</span>
                <br>
                Hvis du har spørsmål, klager eller forslag angående vår personopplysningspolicy eller på annen måte
                trenger å snakke med oss, kan du kontakte oss via chat på www.pubq.se, send oss en e-post på
                support@pubq.se, ring oss på +46736235298 eller kontakt oss per post på følgende adresse: Butängsgatan
                32 602 23 Norrköping
            </div>



        </div>
    </div>
</section>