import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-without-animation-home-three',
  templateUrl: './without-animation-home-three.component.html',
  styleUrls: ['./without-animation-home-three.component.scss']
})
export class WithoutAnimationHomeThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
