<div class="saas-banner">
    <div id="hero-background">

    </div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <!-- Main image -->
                            <img src="assets/img/saas-shape/main-image.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="main-image.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Framtidens sätt att beställa & betala</h1>
                            <p>Ok, <span style="font-weight: bold;">moderna restauranger</span>! Era gäster finns
                                online. Men ni då?
                                Ta emot beställningar genom din helt egna app, hemsida eller expresskassa med PubQ. En
                                platform som redan idag används av ledande varumärken
                            </p>
                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Kom igång
                                        idag!
                                        <span></span></a>
                                    <!-- <a href="https://share.hsforms.com/1_bjAhPRrTp6zj5jaBQhTcQ3xk7q" class="video-btn popup-youtube">
                                        <i class='bx bxs-right-arrow'></i> Watch Videoasdasd
                                    </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape-rotate rotateme">
        <img src="assets/img/saas-shape/shape-rotate.png" alt="img">
    </div> -->
    <!-- <div id="particles-js"></div> -->
</div>
<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="https://compass-group.com" target="_blank">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://restaurangsobi.se" target="_blank" style="background:black">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://tanumstrand.se" target="_blank" style="background:#01215A">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Få tillgång till kraftfull funktionalitet. Utforska PubQ i din egen takt</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-desktop bg-13c4a1'></i>
                    <h3>Onlinebeställningar</h3>
                    <p>Ta emot ordrar genom din egen hemsida. Skriv ut QR-koder och kasta ut din gamla kassa. Inget
                        konto krävs för dina gäster så betalningar genomförs under 20 sekunder</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bx-mobile-alt bg-6610f2'></i>
                    <h3>Brandad app</h3>
                    <p>Din tidsbesparande marknadsföringsmaskin. Dina gäster kan göra allt i appen och du får en närmare
                        relation till dem genom datan de genererar.
                        Kolla in några av våra existerande appar på App Store för att se vad vi menar</p>
                    <hr>
                    <div class="feature-examples">
                        <a href="https://apps.apple.com/se/app/uni3-by-geely/id1563643837?l=en#?platform=iphone"
                            class="example-item"><img
                                src="https://play-lh.googleusercontent.com/duqygLFwJhG0iJQs13D7oNTQ5YsqfYSJrtoVBdM36Tnu9KrO2R8QAVUTpKLGlrm6Eig=s180-rw">Geely</a>
                        <a href="https://apps.apple.com/se/app/food-co-sverige/id1527594442?l=en#?platform=iphone"
                            class="example-item">
                            <img
                                src="https://play-lh.googleusercontent.com/PbdqdsbUSI0Oh4Uaq9h4mM4zZk2oDD8Pjt5WCsH7sxTHDKCmyGV0hOOY70HkQSN0wZI=s180-rw">
                            Food & Co
                        </a>
                        <a href="https://apps.apple.com/se/app/tanumstrand/id1516237551?l=en" class="example-item">
                            <img
                                src="https://play-lh.googleusercontent.com/g-QVZvYY3__d0Hqj3Ba3EIikFrP2KWBycHbaiCbT_vv9fDnceEas8gvvGnOOKDk1H98=s180-rw">
                            Tanumstrand</a>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bx-paper-plane bg-ffb700'></i>
                    <h3>Bordsbokning</h3>
                    <p>Ta emot bordsbokningar online eller genom din app. Designa din bordskarta och sätt igång!</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bxs-car-garage bg-fc3549'></i>
                    <h3>Hur får de sin mat?</h3>
                    <p>Slå på olika sätt för gästen att få sin order
                        <br>
                        - Takeaway <br>
                        - Utkörning till adress <br>
                        - Utkörning till fasta platser <br>
                        - Bordsbeställning
                    </p>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-coupon bg-00d280'></i>
                    <h3>Lojalitet & bonus</h3>
                    <p>
                        10:e maten gratis? Belöna dina gäster med kuponger och bonuspoäng och bygg en långsiktig
                        relation som gör att de kommer tillbaka om och om igen
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-megaphone bg-ff612f'></i>
                    <h3>Nyheter & Artiklar</h3>
                    <p>
                        Vad händer på den lokala restaurangen? Varje restaurang i din kedja kan publicera sina egna
                        nyheter och events. Det håller ditt varumärke fräscht och intressant för era gäster
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ptb-100 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2 class="mb-2">Upptäck</h2>
            <p>Bli inspirerad av hur våra kunder använder PubQ</p>
        </div>
    </div>
    <div class="overview-item">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/feature2.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">01</span>
                        <h3>Sobi ökar sin försäljning</h3>
                        <p>
                            På <span style="font-weight: bold;">4</span> månader har Sobis app & onlineförsäljning ökat
                            med över
                            <span style="font-weight: bold;">120%</span>.
                            Placerade strax utanför centrumkärnan så har Sobi lyckats blivit förstahands valet för
                            affärsluncher, middagstakeaway och levererar en premiumkänsla genom hela konceptet.
                            Genom att konstant hålla deras sociala medier-flöden uppdaterade med smakrika rätter kan de
                            attrahera gäster i alla åldrar
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Publicera bilder dagligen</li>
                            <li><i class='bx bx-badge-check'></i>Fokusera på maten och känslan</li>
                            <li><i class='bx bxl-facebook-square'></i><a
                                    href="https://www.facebook.com/restaurangsobi">Facebook</a></li>
                            <li><i class='bx bxl-instagram'></i><a
                                    href="https://www.instagram.com/restaurangsobi">Instagram</a></li>
                        </ul>
                        <!-- <a routerLink="/about" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Read
                            More <span></span></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-item">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">02</span>
                        <h3>Utkörningskungar på Sveriges mindre orter</h3>
                        <p>De senaste fyra månaderna har <a class="highlight"
                                href="https://apps.apple.com/se/app/joans/id1345073153#?platform=iphone">Joan's</a>
                            adderat en utkörningsbil extra i månaden för att kunna möta efterfrågan.
                            Tack vare sitt hårda jobb har de etablerat sig som det självklara valet för appbeställningar
                            på sina respektive orter. Så när COVID slog till och de började med hemleverans så var
                            lösningen redan på plats samtidigt
                            som efterfrågan fortsätter att öka </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Finns på mindre orter</li>
                            <li><i class='bx bx-badge-check'></i>Levererar hög kvalité</li>
                            <li><i class='bx bx-badge-check'></i>Attrahera gäster med erbjudanden</li>
                        </ul>
                        <!-- <a routerLink="/about" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Read
                            More <span></span></a> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/home-saas/feature3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-item">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/feature4.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">03</span>
                        <h3>
                            Förstklassig upplevelse. Från food trucks till företagsrestauranger
                        </h3>
                        <p>
                            Compass Group driver över 1700 restauranger i norden. Med PubQ kan de erbjuda rabatter och
                            leveranser till lunchrum samtidigt som de reducerar svinn genom att ta emot ordrar
                            genom våra appar och hemsidor
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Lunchbiljetter genom app och hemsida</li>
                            <li><i class='bx bx-badge-check'></i>Erbjudanden och bonuspoäng i apparna</li>
                            <li><i class='bx bx-badge-check'></i>Slipp köa på arbetsdagen. Pushnotifikationer berättar
                                för gästen när det är dags att hämta sin mat</li>
                        </ul>
                        <!-- <a routerLink="/about" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Read
                            More <span></span></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow fadeInLeft" data-wow-delay=".1s">
                <div class="image">
                    <img src="assets/img/home-saas/feature1.png" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <p>PubQ gör allt du vill och lite till</p>
                    <div class="features-inner-content">

                        <div class="features-item wow" data-wow-delay=".2s">
                            <i class='bx bxl-snapchat bg-6610f2 blt-radius-0'></i>
                            <h3>Starta ett ghost kitchen</h3>
                            <p>Ta bara emot onlinebeställningar, maxa din marknadsföring och fokusera på att leverera så
                                mycket som möjligt ur ett så litet kök som möjligt
                            </p>
                        </div>
                        <div class="features-item wow" data-wow-delay=".3s">
                            <i class='bx bx-barcode bg-13c4a1 blt-radius-0'></i>
                            <h3>Gör din uteservering till en succé</h3>
                            <p>Låt gästerna beställa genom att scanna en QR-kod eller ladda ned din app. Öka
                                merförsäljningen och kundnöjdheten på samma gång
                            </p>
                        </div>
                        <div class="features-item wow" data-wow-delay="1s">
                            <i class='bx bx-trash bg-ff612f blt-radius-0'></i>
                            <h3>Ta. Bort. Kassan</h3>
                            <p>Varför inte ta bort kassan helt och hållet? Hitta nya spännande sätt att interagera
                                med gästen på när du presenterar menyn. Låt dem sedan lägga sin order genom sin telefon.
                                Ta gästinteraktionen till nästa nivå och gör dig av med gamla vanor
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image">
    </div>
</section>



<!-- <section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i
                    class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section> -->

<!-- <section class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/home-saas/feature5.png" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>Build Beautiful Interface Into Your Application</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip.</p>
                        <ul>
                            <li><i class="bx bx-badge-check"></i>Professional Code</li>
                            <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
                            <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
                            <li><i class="bx bx-badge-check"></i>Camera Filter</li>
                            <li><i class="bx bx-badge-check"></i>Start Coding Format</li>
                        </ul>
                        <a routerLink="/about" class="default-btn black-btn"><i class="bx bxs-arrow-to-right"></i>Read
                            More <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image">
    </div>
</section> -->

<section class="feedback-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Vad säger kunderna om <span>PubQ</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/tm1.png" alt="image">
                <div class="feedback-desc">
                    <p>Med PubQ och vår app har vi skapat en självgående takeaway som sparar oss tonvis med samtal.
                        Istället kan vi helt fokusera på att se till att våra gäster i restaurangen har det bra.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Bibbi Kesenci</h3>
                        <span>Restaurangchef, Sobi</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/tm2.png" alt="image">
                <div class="feedback-desc">
                    <p>Joan's-appen har hjälpt oss att effektivisera servicen och köket både under pandemin och innan. Nu kommer beställningar in på skärmen oavsett om gästerna sitter i restaurangen, beställer takeaway eller utkörning. Allt bara flyter på! </p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Jonny Morad</h3>
                        <span>Restaurangchef, Joan's</span>
                    </div>
                </div>
            </div>
            <!-- <div class="single-feedback-item">
                <img src="assets/img/tm3.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span
                                            class="tooltips bx bxs-info-circle" data-toggle="tooltip"
                                            data-placement="right"
                                            title="Tight pants next level keffiyeh you probably haven't heard of them."></span>
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>PubQ</span></h2>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    Do I need a POS system to use PubQ? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    PubQ POS is built right into the platform so you don't need any other solution to accept orders and create receipts
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What access do I have on a free trial? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Does the price go up as my team gets larger? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How can I cancel/pause my subscription? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card mb-0">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I pay via an invoice? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Vi kan väl prata</h2>
            <a routerLink="/contact" class="default-btn">
                <i class="bx bxs-edit"></i> Skriv något<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>