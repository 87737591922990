import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy-sv',
  templateUrl: './policy-sv.component.html',
  styleUrls: ['./policy-sv.component.scss']
})
export class PolicySvComponent implements OnInit {
  public company = "PubQ";
  public companyNr = "559057-4843";
  public lang = "se";
  constructor(private activatedRoute: ActivatedRoute) {
    let params = this.activatedRoute.snapshot.queryParams;
    if (params.c && params.nr) {
      this.company = params.c;
      this.companyNr = params.nr;
    }
    if (params.lang)
      this.lang = params.lang;
  }
  ngOnInit() {
  }

}
