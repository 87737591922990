<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Skicka ett meddelande</h2>
            <p>Vi svarar på alla frågor och funderingar</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Vill du ha hjälp att komma igång?</h3>
                        <p>Ta de första stegen mot att bli en digital restaurang</p>
                        <p>Vi kan digitala beställningar. Vi vet hur viktigt det är med en enkel och sömlös upplevelse när det kommer till beställningar i restaurangbranschen. Därför ser vi fram emot att arbeta tillsammans som partners</p>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Kontakta oss</h3>
                        <iframe style="border:none; height:60vh;width:100%;"src="https://share.hsforms.com/1_bjAhPRrTp6zj5jaBQhTcQ3xk7q"></iframe>
                        <!-- <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'></i>Send Message<span></span></button>
                                </div>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="https://compass-group.com" target="_blank">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://restaurangsobi.se" target="_blank" style="background:black">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://tanumstrand.se" target="_blank" style="background:#01215A">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>