import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy-no',
  templateUrl: './policy-no.component.html',
  styleUrls: ['./policy-no.component.scss']
})
export class PolicyNoComponent implements OnInit {
  public company = "PubQ";
  public companyNr = "559057-4843";
  constructor(private activatedRoute: ActivatedRoute) {
    let params = this.activatedRoute.snapshot.queryParams;
    if (params.c && params.nr) {
      this.company = params.c;
      this.companyNr = params.nr;
    }
  }

  ngOnInit() {
  }

}
