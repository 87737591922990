<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Policy</h2>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70" *ngIf="lang === 'se'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <span style="font-weight: bold;">Inledning</span>
                <br>
                {{company}}, {{companyNr}} (”Bolaget” eller ”Vi”) ansvarar för alla dina personuppgifter som behandlas
                av oss.
                Säkerhet är mycket viktigt för oss. Vi respekterar din integritet och åtar oss att skydda dina
                personuppgifter i enlighet med denna policy.

                Dina personuppgifter behandlas med stöd av ditt samtycke. Genom att godkänna den här policyn samtycker
                du till behandlingen av dina personuppgifter i enlighet med nedan.
                <br>
                <br>
                <span style="font-weight: bold;">Personuppgiftsansvarig</span>
                <br>
                Bolaget är personuppgiftsansvarig för dina personuppgifter som Vi behandlar och hanterar i enlighet med
                gällande lagstiftning. Våra kontaktuppgifter framgår av punkten 9.
                <br>
                <br>
                <span style="font-weight: bold;">Syfte</span>
                <br>
                Syftet med denna personuppgiftspolicy är att förklara hur Bolaget behandlar, innehar, lagrar, hanterar
                samt skyddar och delar information om dig genom PubQs mobilapplikationer som du laddar ner från
                handelsplatser för applikationer för användning i mobila enheter (”Applikationen”). Vänligen läs denna
                policy noggrant. Ni är varmt välkomna att kontakta oss enligt de kontaktuppgifter som framgår under
                punkten 9 vid frågor eller funderingar.
                <br>
                <br>
                <span style="font-weight: bold;">Omfattning</span>
                <br>
                Denna personuppgiftspolicy är endast tillämplig för den information som Bolaget samlar in genom
                Applikationen, och vissa associerade webbsidor som görs tillgängliga genom Applikationen eller online.
                Den här policyn är inte tillämpbar på information som Bolaget samlat offline eller genom andra verktyg.
                Applikationen kan innehålla länkar till tredjepartssidor som inte ägs eller kontrolleras av Bolaget. Vi
                vill därför informera om att Bolaget inte är ansvarigt för rutinen för och behandlingen av
                personuppgifter på sådana sidor. Vi uppmanar dig därför att vara uppmärksam på när du lämnar
                Applikationen och uppmanar dig att läsa de personuppgiftspolicys som tillhandahålls på respektive sida
                som samlar personuppgifter.
                <br>
                Användarvillkor. Vänligen notera att din användning av Applikationen omfattas av Licensavtal för
                Slutanvändare som finns tillgänglig på www.pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Information som vi samlar in om dig</span>
                <br>
                Uppgifter som du tillhandahåller till oss, till exempel när du skapar eller uppdaterar ditt konto,
                registrerar dig för att använda Applikationen, begär tjänster från oss, begär information från oss,
                kontaktar kundservice eller på andra sätt kommunicerar med oss. Uppgifterna kan omfatta namn, hem- och
                leveransadress(er), e-postadress, mobiltelefonnummer, födelsedatum och kön.
                <br>
                <br>
                <span style="font-weight: bold;">Uppgifter vi kan samla in genom din användning av Applikationen</span>
                <br>
                Google analytics och Firebase analytics
                <br>
                Vi använder Google analytics och Firebase analytics, två webbanalysprogram som tillhandahålls av Google,
                Inc (”Google”) för att samla in viss information avseende ditt besök på vår hemsida, www.pubq.se. Dessa
                uppgifter kan omfatta: detaljer kring ditt besök på hemsidan, platsinformation, sidor du besöker och
                information om din mobila enhet och internetuppkoppling, inklusive IP-adress, operativsystem och
                webbläsare.
                <br>
                <br>
                <span style="font-weight: bold;">Cookies </span>
                <br>
                Vi använder cookies och andra teknologier för att ta emot och lagra viss typ av information när du
                interagerar med vår Applikation genom din mobila enhet. Alla som besöker en webbplats eller applikation
                med cookies ska enligt lag (2003:389) om elektronisk kommunikation få information om dem samt medvetet
                godkänna att de används. En cookie är en liten textfil som sparas på besökarens dator. Filen innehåller
                en rad karaktärer som skickas till din mobila enhet när du besöker en hemsida eller använder en
                applikation. Cookies kan lagra unika kännetecken, använda preferenser och annan information. Vi använder
                cookies för att förbättra kvalitéten på våra tjänster, bland annat för att lagra användarinställningarna
                och för att spåra slutanvändare.
                <br>
                <br>
                Tredjeparts-cookies
                <br>

                Cookies som används för att samla information för skräddarsytt innehåll samt för webbstatistik som
                kommer från någon annan än av oss som ansvariga för Applikationen kan vara s.k. tredjeparts-cookies. Vi
                använder oss av Google analytics vilka använder cookies i form av textfiler som sparas på din dator, för
                att hjälpa oss analysera hur du använder hemsidan och Applikationen. Du kan få mer information om hur
                Google använder data när du besöker vår webbplats genom att besöka ”How Google uses data when you use
                our partners’ sites or apps”, som finns tillgänglig på www.google.com/policies/privacy/partners/).
                <br>
                <br>
                Platsdata
                <br>
                Vi kan också automatiskt samla platsdata genom din mobila enhet. Denna information kan användas för att
                förbättra kvaliteten på våra tjänster, för att leda dig till tjänster i ditt område eller för att samla
                data på din aktivitetsnivå. Du kan aktivera och avaktivera denna funktion genom inställningarna i din
                mobila enhet.
                <br>
                <br>
                Andra datainsamlingsteknologier
                <br>
                Applikationen kan också samla uppgifter genom Firebase analytics, en analyseringsapplikation för
                mobiler, för att logga datorkrascher och andra non-fatal som kan uppstå när du använder Applikationen.
                Uppgifterna som samlas kan inbegripa vad du gjorde vid tiden för kraschen eller felkoden och vilka
                sökningar du gjort vid användning av Applikationen. Denna data är inte hänförlig till ditt PubQ-konto
                utan hjälper oss att förbättra Applikationen.
                <br>
                <br>
                Samtycke till användandet och möjlighet att tacka nej till cookies
                <br>
                Genom att använda Applikationen samtycker du till användningen av cookies enligt ovan. Det finns
                möjlighet att tacka nej till cookies genom att radera cookies från din hårddisk, vilket kan ske när som
                helst. Att radera cookies kan göra att dina personliga inställningar försvinner. Du kan också ändra
                inställningarna i din webbläsare så att den nekar att cookies sparas på din hårddisk. Detta kan försämra
                funktionaliteten på vissa webbsidor och kan leda till att vissa funktioner och tjänster i Applikationen
                slutar att fungera. Har din webbläsare stöd för ett så kallat ”inkognitoläge” kan du aktivera det för
                att automatiskt radera alla cookies varje gång du stänger din webbläsare.
                <br>
                <br>
                Ingen information från barn under 18
                <br>
                Vår Applikation är inte avsedd för användning av barn under 18 år. Ingen under 18 år får mata in
                uppgifter i Applikationen. Vi samlar inte medvetet in uppgifter från någon under 18 år. Om du är under
                18 år, använd inte och mata inte in någon information i Applikationen. Om vi blir medvetna om att vi har
                samlat eller tagit emot information från ett barn under 18 år utan att ha målsmans tillstånd kommer vi
                att radera informationen. Om du tror att vi kan ha information om ett barn under 18 år, ring
                +46736235298 eller meddela oss via support@pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Vi behandlar dina personuppgifter för följande ändamål</span>
                <br>
                För att tillhandahålla enheter, tjänster och information. Vi samlar in uppgifter från dig för att
                fullgöra våra avtalsenliga åtaganden gentemot dig och för att tillhandahålla enheter och tjänster som du
                begär när du använder Applikationen samt tillhandahålla information som du begär av oss. För detta
                ändamål använder Vi dina uppgifter för att kontakta dig gällande dina beställningar och för att skicka
                beställda enheter till dig. Vi kan skicka nyhetsbrev, information om enheter och våra tjänster, nya
                erbjudanden och/eller annat marknadsföringsmaterial till dig. Vi kan också använda din information för
                att administrera enkäter som Vi utför för att förstå våra användares behov.
                <br>
                <br>
                Reklam. Vi använder inte din aktivitet på Applikationen för att visa annonser för PubQ eller för andra
                tredjeparter. Vi tillhör inte några annonsnätverk som använder din webbläsningshistorik på andra
                webbplatser för att visa intressebaserade annonser och Vi använder ingen cookie-teknik som är utformad
                för att spåra dig efter att du lämnat Applikationen.
                <br>
                Ditt samtycke. När Vi har ditt samtycke kan Vi lämna uppgifter till andra företag, organisationer eller
                personer utanför Bolaget. Du kan när som helst meddela oss att du önskar återkalla detta samtycke. I
                sådant fall kommer ingen ytterligare behandling av personuppgifter om dig att äga rum.
                <br>
                <br>
                <span style="font-weight: bold;">Vem vi delar din information med</span>
                <br>
                För att uppfylla de ändamål för vilket uppgifterna samlats in kan dina uppgifter komma att registreras,
                innehas (lagras), användas och överföras till tredjepartsleverantörer till oss enligt vad som anges
                nedan.
                <br>
                <br>
                Tredjepartsleverandører. Vi kan tillhandahålla information till godkända tredjepartstjänsteleverantörer
                så att tjänsteleverantören kan hantera servrarna som används för Applikationen, för att hantera
                beställningar, beställa och leverera varor samt leverera tjänster som du begär av oss. Vi kan dela din
                information med tredjeparts tjänsteleverantörer för att tillhandahålla support via t.ex. telefon. Dessa
                tjänsteleverantörer kommer att få tillgång till dina uppgifter för att tillhandahålla de av dig begärda
                tjänsterna. När de får tillgång till dina uppgifter tillämpar Vi rimliga avtalsenliga och tekniska skydd
                för att begränsa leverantörens användning av den information de får tillgång till för att hjälpa oss att
                tillhandahålla tjänsten.
                <br>
                <br>
                Rättsliga förfaranden. Vi kommer att dela personlig information med tredjepartsföretag, organisationer
                eller personer utanför Bolaget om Vi har anledning att tro att behandlingen, hanteringen eller
                offentliggörande av informationen är nödvändig för att: a) uppfylla alla tillämpliga lagar, reglering,
                rättsliga processer eller verkställbara myndighetsbeslut; (b) Verkställa tillämpliga användarvillkor,
                inklusive utredning av potentiella överträdelser; (c) Upptäcka, förebygga eller på annat sätt hantera
                bedrägeri, säkerhet eller tekniska problem; eller (d) Skydda Bolaget mot skador på rättigheter, egendom
                eller säkerhet, skador gentemot våra användare, kunder eller allmänheten enligt vad som krävs eller är
                tillåtet enligt lag.
                <br>
                <br>
                Överföring i händelse av försäljning eller ändring av kontroll. Om äganderätten till vår verksamhet
                ändras eller Vi på annat sätt överför tillgångar som är relaterade till vår verksamhet eller
                Applikationen till en ny ägare kan Vi överföra dina uppgifter till den nya ägaren om ändamålet med de
                insamlade uppgifterna är detsamma hos den nya ägaren som i denna personuppgiftspolicy. Du kommer att
                informeras om vem som är ny personuppgiftsansvarig om en ny ägare övertar dina personuppgifter. Vid en
                övergång till ny ägare gäller denna personuppgiftspolicy för dina uppgifter om du inte godkänner en ny
                personuppgiftspolicy.
                <br>
                <br>
                Samlad information. Vi kan utan begränsning avslöja aggregerad information om våra användare och annan
                information som inte identifierar någon individ.
                <br>
                <br>
                <span style="font-weight: bold;">Dina rättigheter och Tillgång TILL DIN INFORMATION</span>
                <br>
                Vi ansvarar för att dina personuppgifter behandlas i enlighet med gällande lagstiftning. Du kan när som
                helst begära att få rätta, avidentifiera, komplettera eller radera dina personuppgifter som är
                felaktiga, ofullständiga eller missvisande. Vi kan även på eget initiativ göra detta.

                Du har rätt att av oss begära tillgång till och rättelse eller radering av dina personuppgifter, eller
                att begära begränsning av behandlingen av personuppgifter som rör dig samt rätt att invända mot
                behandlingen. Du har vidare rätt att inge eventuella klagomål till Datainspektionen. Du kan komma åt och
                uppdatera viss information som Vi har angående ditt konto genom att logga in på ditt konto och gå till
                Profil-sektionen i vår Applikation. Om du har frågor om uppgifter som Vi har om dig eller behöver
                uppdatera din information, kan du kontakta oss via telefon, e-post eller brev enligt
                kontaktinformationen nedan. Du kan välja bort marknadsföring via Applikationen genom att använda
                funktionen för att välja bort eller avaktivera e-postmeddelandena.

                Du kan avsluta ditt konto genom att kontakta oss via e-post eller telefon enligt kontaktinformationen
                nedan under 9 och begära att Vi avslutar ditt konto. Om du avslutar ditt konto kommer Vi inte längre att
                använd dina uppgifter och kommer inte dela den med tredje part.

                Du har rätt att begära tillgång till dina personuppgifter och få information bland annat om vilka
                personuppgifter som finns lagrade hos oss, ändamålen med behandlingen av dessa personuppgifter, de
                mottagare eller kategorier av mottagare till vilka personuppgifterna har eller ska lämnas ut, den
                förutsedda period eller kriterierna för att fastställa under vilken period personuppgifterna kommer att
                lagras.

                För det fall du önskar få de personuppgifter du lämnat överförda i syfte att kunna använda dem på annat
                håll (dataportabilitet) ber vi dig kontakta PubQ.
                <br>
                <br>
                <span style="font-weight: bold;">Hur länge vi behandlar dina uppgifter</span>
                <br>
                Dina personuppgifter bevaras endast så länge som det finns ett behov av att bevara dem för att fullgöra
                de ändamål som uppgifterna samlades in för i enlighet med denna personuppgiftspolicy.
                <br>
                <br>
                <span style="font-weight: bold;">SÄKERHET avseende DIN INFORMATION</span>
                <br>
                Vi använder fysiska, tekniska och administrativa säkerhetsåtgärder och skyddsåtgärder som håller
                branschstandard för att skydda din personliga integritet och säkerhet. Eftersom Internet inte är en 100
                % säker miljö kan Vi inte garantera säkerheten för information du överför till oss. Det finns ingen
                garanti för att information inte nås, avslöjas, ändras eller förstörs genom brott mot någon av våra
                fysiska eller tekniska skyddsåtgärder. Det är ditt ansvar att skydda din inloggningsinformation.
                <br>
                <br>
                <span style="font-weight: bold;">ÄNDRINGAR AV VÅR personuppgiftspolicy</span>
                <br>
                Vår personuppgiftspolicy kan ändras utan förvarning. Vi kommer inte att försvaga dina rättigheter enligt
                denna personuppgiftspolicy utan ditt samtycke eller ändra dessa i strid med gällande lag. Vi kommer att
                publicera alla ändringar av personuppgiftspolicyn på vår webbplats www.pubq.se. Om ändringarna är
                betydande så kommer Vi att meddela dig genom ett särskilt meddelande. Vi kommer också att behålla
                tidigare versioner av denna personuppgiftspolicy i ett arkiv för din granskning. Din fortsatta
                användning av Applikationen efter att vi gjort ändringar anses utgöra godkännande av dessa ändringar.
                <br>
                <br>
                <span style="font-weight: bold;">Kontaktinformation</span>
                <br>
                Om du har några frågor, klagomål eller förslag gällnade vår personuppgiftspolicy eller på annat sätt
                behöver kontakta oss, vänligen kontakta oss via chat på www.pubq.se, skicka e-post till oss på
                support@pubq.se, ring oss på +46736235298 eller kontakta oss per post på följande adress: Butängsgatan
                32 602 23 Norrköping
            </div>



        </div>
    </div>
</section>
<section class="features-area pt-100 pb-70" *ngIf="lang === 'en'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <span style="font-weight: bold;">Introduction</span>
                <br>
                {{company}}, {{companyNr}} ("the Company" or "We") is responsible for all your personal data processed
                by us. Security is very important to us. We respect your privacy and are committed to protecting your
                personal data in accordance with this policy.

                Your personal data is processed with your consent. By accepting this policy, you consent to the
                processing of your personal data in accordance with the following.
                <br>
                <br>
                <span style="font-weight: bold;">Data Controller</span>
                <br>
                The Company is the data controller for your personal data that We process and handle in accordance with
                applicable law. Our contact details are stated in point 9.
                <br>
                <br>
                <span style="font-weight: bold;">Purpose</span>
                <br>
                The purpose of this privacy policy is to explain how the Company processes, holds, stores, manages,
                protects, and shares information about you through PubQ's mobile applications that you download from
                application marketplaces for use on mobile devices ("the Application"). Please read this policy
                carefully. You are warmly welcome to contact us using the contact details provided under point 9 for
                questions or concerns.
                <br>
                <br>
                <span style="font-weight: bold;">Scope</span>
                <br>
                This privacy policy applies only to the information that the Company collects through the Application
                and certain associated web pages made available through the Application or online. This policy does not
                apply to information collected offline or through other means by the Company. The Application may
                contain links to third-party sites that are not owned or controlled by the Company. Therefore, we would
                like to inform you that the Company is not responsible for the routine and processing of personal data
                on such sites. We therefore encourage you to be attentive when leaving the Application and urge you to
                read the privacy policies provided on each page collecting personal data.
                <br>
                Terms of Use. Please note that your use of the Application is subject to the End User License Agreement
                available at www.pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Information We Collect About You</span>
                <br>
                Data you provide to us, such as when you create or update your account, register to use the Application,
                request services from us, request information from us, contact customer service, or otherwise
                communicate with us. This data may include name, home and delivery addresses, email address, mobile
                phone number, date of birth, and gender.
                <br>
                <br>
                <span style="font-weight: bold;">Data We May Collect Through Your Use of the Application</span>
                <br>
                Google Analytics and Firebase Analytics
                <br>
                We use Google Analytics and Firebase Analytics, two web analytics programs provided by Google, Inc.
                ("Google") to collect certain information about your visit to our website, www.pubq.se. This data may
                include: details about your visit to the website, location information, pages you visit, and information
                about your mobile device and internet connection, including IP address, operating system, and browser.
                <br>
                <br>
                <span style="font-weight: bold;">Cookies</span>
                <br>
                We use cookies and other technologies to receive and store certain types of information when you
                interact with our Application through your mobile device. Anyone visiting a website or application with
                cookies should, according to the Electronic Communications Act (2003:389), receive information about
                them and consciously agree to their use. A cookie is a small text file that is saved on the visitor's
                computer. The file contains a series of characters that are sent to your mobile device when you visit a
                website or use an application. Cookies can store unique identifiers, user preferences, and other
                information. We use cookies to improve the quality of our services, including storing user settings and
                tracking end-users.
                <br>
                <br>
                Third-party Cookies
                <br>

                Cookies used to collect information for customized content and web statistics that come from someone
                other than us responsible for the Application may be called third-party cookies. We use Google
                Analytics, which uses cookies in the form of text files saved on your computer, to help us analyze how
                you use the website and the Application. You can get more information about how Google uses data when
                you visit our website by visiting "How Google uses data when you use our partners' sites or apps",
                available at www.google.com/policies/privacy/partners/).
                <br>
                <br>
                Location Data
                <br>
                We may also automatically collect location data through your mobile device. This information may be used
                to improve the quality of our services, to guide you to services in your area, or to collect data on
                your activity level. You can enable and disable this feature through the settings on your mobile device.
                <br>
                <br>
                Other Data Collection Technologies
                <br>
                The Application may also collect data through Firebase Analytics, a mobile analytics application, to log
                computer crashes and other non-fatal issues that may occur when using the Application. The data
                collected may include what you were doing at the time of the crash or error code and what searches you
                made while using the Application. This data is not attributable to your PubQ account but helps us
                improve the Application.
                <br>
                <br>
                Consent to Use and Opt-out of Cookies
                <br>
                By using the Application, you consent to the use of cookies as described above. There is an option to
                opt out of cookies by deleting cookies from your hard drive, which can be done at any time. Deleting
                cookies may cause your personal settings to disappear. You can also change your browser settings to
                refuse cookies from being saved to your hard drive. This may impair the functionality of certain
                websites and may cause certain features and services in the Application to stop working. If your browser
                supports a so-called "incognito mode," you can enable it to automatically delete all cookies every time
                you close your browser.
                <br>
                <br>
                No Information from Children Under 18
                <br>
                Our Application is not intended for use by children under 18 years of age. No one under 18 years of age
                may enter data into the Application. We do not knowingly collect information from anyone under 18 years
                of age. If you are under 18 years of age, do not use or enter any information into the Application. If
                we become aware that we have collected or received information from a child under 18 years of age
                without parental consent, we will delete the information. If you believe we may have information about a
                child under 18 years of age, please call +46736235298 or notify us at support@pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">We Process Your Personal Data for the Following Purposes</span>
                <br>
                To provide devices, services, and information. We collect data from you to fulfill our contractual
                obligations to you and to provide devices and services requested by you when using the Application and
                to provide information requested by you. For this purpose, We use your data to contact you regarding
                your orders and to send ordered devices to you. We may send newsletters, information about devices and
                our services, new offers, and/or other marketing materials to you. We may also use your information to
                administer surveys that We conduct to understand our users' needs.
                <br>
                <br>
                Advertising. We do not use your activity on the Application to display advertisements for PubQ or for
                other third parties. We do not belong to any advertising networks that use your web browsing history on
                other websites to display interest-based advertisements, and We do not use any cookie technology
                designed to track you after you leave the Application.
                <br>
                Your Consent. When We have your consent, We may provide information to other companies, organizations,
                or individuals outside the Company. You may at any time notify us that you wish to revoke this consent.
                In such a case, no further processing of your personal data will take place.
                <br>
                <br>
                <span style="font-weight: bold;">Who We Share Your Information With</span>
                <br>
                To fulfill the purposes for which the data was collected, your data may be recorded, held (stored),
                used, and transferred to third-party providers to us as specified below.
                <br>
                <br>
                Third-Party Providers. We may provide information to approved third-party service providers so that the
                service provider can manage the servers used for the Application, to manage orders, order and deliver
                goods, and deliver services requested by you. We may share your information with third-party service
                providers to provide support via, for example, telephone. These service providers will have access to
                your data to provide the services requested by you. When they access your data, We apply reasonable
                contractual and technical protections to limit the provider's use of the information they access to help
                us provide the service.
                <br>
                <br>
                Legal Proceedings. We will share personal information with third-party companies, organizations, or
                individuals outside the Company if We have reason to believe that the processing, handling, or
                disclosure of the information is necessary to: a) comply with any applicable laws, regulations, legal
                processes, or enforceable government requests; (b) Enforce applicable terms of use, including
                investigating potential violations; (c) Detect, prevent, or otherwise address fraud, security, or
                technical issues; or (d) Protect the Company against harm to rights, property, or safety, harm to our
                users, customers, or the public as required or permitted by law.
                <br>
                <br>
                Transfer in the Event of Sale or Change of Control. If ownership of our business changes or We otherwise
                transfer assets related to our business or the Application to a new owner, We may transfer your data to
                the new owner if the purpose of the collected data is the same with the new owner as in this privacy
                policy. You will be informed of who the new data controller is if a new owner takes over your personal
                data. In the event of a transfer to a new owner, this privacy policy applies to your data unless you
                approve a new privacy policy.
                <br>
                <br>
                Aggregate Information. We may disclose aggregated information about our users and other information that
                does not identify any individual without limitation.
                <br>
                <br>
                <span style="font-weight: bold;">Your Rights and Access to Your Information</span>
                <br>
                We are responsible for processing your personal data in accordance with applicable law. You may at any
                time request to correct, anonymize, complete, or delete your personal data that is incorrect,
                incomplete, or misleading. We may also do this on our own initiative.

                You have the right to request access to and correction or deletion of your personal data, or to request
                restriction of processing of personal data concerning you and the right to object to processing. You
                also have the right to lodge any complaints with the Swedish Data Protection Authority. You can access
                and update certain information We have about your account by logging into your account and going to the
                Profile section of our Application. If you have questions about the data We have about you or need to
                update your information, you can contact us by phone, email, or mail using the contact information
                below. You can opt out of marketing through the Application by using the opt-out or email deactivation
                feature.

                You can terminate your account by contacting us by email or phone using the contact information below
                under 9 and requesting that We terminate your account. If you terminate your account, We will no longer
                use your data and will not share it with a third party.

                You have the right to request access to your personal data and information including what personal data
                is stored by us, the purposes of processing of this personal data, the recipients or categories of
                recipients to whom the personal data have been or will be disclosed, the envisaged period or the
                criteria for determining the period for which the personal data will be stored.

                In the event you wish to have the personal data you provided transferred for the purpose of being able
                to use them elsewhere (data portability), We ask you to contact PubQ.
                <br>
                <br>
                <span style="font-weight: bold;">How Long We Process Your Data</span>
                <br>
                Your personal data is only retained as long as there is a need to retain it to fulfill the purposes for
                which the data was collected in accordance with this privacy policy.
                <br>
                <br>
                <span style="font-weight: bold;">Security Regarding Your Information</span>
                <br>
                We use physical, technical, and administrative security measures and safeguards that meet industry
                standards to protect your personal privacy and security. Since the Internet is not a 100% secure
                environment, We cannot guarantee the security of information you transmit to us. There is no guarantee
                that information will not be accessed, disclosed, altered, or destroyed by breaches of any of our
                physical or technical safeguards. It is your responsibility to protect your login information.
                <br>
                <br>
                <span style="font-weight: bold;">Changes to Our Privacy Policy</span>
                <br>
                Our privacy policy may change without notice. We will not weaken your rights under this privacy policy
                without your consent or change them in violation of applicable law. We will publish any changes to the
                privacy policy on our website www.pubq.se. If the changes are significant, We will notify you through a
                separate notice. We will also retain previous versions of this privacy policy in an archive for your
                review. Your continued use of the Application after We make changes is deemed to constitute acceptance
                of those changes.
                <br>
                <br>
                <span style="font-weight: bold;">Contact Information</span>
                <br>
                If you have any questions, complaints, or suggestions regarding our privacy policy or need to contact us
                for any other reason, please contact us via chat at www.pubq.se, email us at support@pubq.se, call us at
                +46736235298, or contact us by mail at the following address: Butängsgatan 32 602 23 Norrköping
            </div>



        </div>
    </div>
</section>
<section class="features-area pt-100 pb-70" *ngIf="lang === 'da'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <span style="font-weight: bold;">Indledning</span>
                <br>
                {{company}}, {{companyNr}} ("Virksomheden" eller "Vi") er ansvarlig for alle dine personoplysninger, der behandles af os.
                Sikkerhed er meget vigtigt for os. Vi respekterer dit privatliv og forpligter os til at beskytte dine
                personoplysninger i overensstemmelse med denne politik.

                Dine personoplysninger behandles med dit samtykke. Ved at acceptere denne politik giver
                du samtykke til behandling af dine personoplysninger som beskrevet nedenfor.
                <br>
                <br>
                <span style="font-weight: bold;">Dataansvarlig</span>
                <br>
                Virksomheden er dataansvarlig for dine personoplysninger, som Vi behandler og håndterer i overensstemmelse med
                gældende lovgivning. Vores kontaktoplysninger fremgår af punkt 9.
                <br>
                <br>
                <span style="font-weight: bold;">Formål</span>
                <br>
                Formålet med denne persondatapolitik er at forklare, hvordan Virksomheden behandler, opbevarer, håndterer,
                beskytter og deler oplysninger om dig via PubQ's mobilapplikationer, som du downloader fra
                appbutikker til brug på mobile enheder ("Applikationen"). Venligst læs denne
                politik omhyggeligt. Du er velkommen til at kontakte os på de kontaktoplysninger, der fremgår under
                punkt 9, hvis du har spørgsmål eller bekymringer.
                <br>
                <br>
                <span style="font-weight: bold;">Rækkevidde</span>
                <br>
                Denne persondatapolitik gælder kun for de oplysninger, som Virksomheden indsamler gennem
                Applikationen og visse tilknyttede websider, som er tilgængelige via Applikationen eller online.
                Denne politik gælder ikke for oplysninger, som Virksomheden indsamler offline eller gennem andre værktøjer.
                Applikationen kan indeholde links til tredjepartswebsites, som ikke ejes eller kontrolleres af Virksomheden. Vi
                vil derfor informere om, at Virksomheden ikke er ansvarlig for rutinen og behandlingen af
                personoplysninger på sådanne sider. Vi opfordrer dig derfor til at være opmærksom, når du forlader
                Applikationen, og opfordrer dig til at læse de persondatapolitikker, der leveres på de respektive sider
                der indsamler personoplysninger.
                <br>
                Brugervilkår. Bemærk venligst, at din brug af Applikationen er underlagt Slutbrugerlicensaftalen
                tilgængelig på www.pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Oplysninger, vi indsamler om dig</span>
                <br>
                Oplysninger, som du giver os, f.eks. når du opretter eller opdaterer din konto,
                registrerer dig til brug af Applikationen, anmoder om tjenester fra os, anmoder om oplysninger fra os,
                kontakter kundeservice eller på anden måde kommunikerer med os. Oplysningerne kan omfatte navn, hjemme- og
                leveringsadresser, e-mailadresse, mobiltelefonnummer, fødselsdato og køn.
                <br>
                <br>
                <span style="font-weight: bold;">Oplysninger, vi kan indsamle ved din brug af Applikationen</span>
                <br>
                Google Analytics og Firebase Analytics
                <br>
                Vi bruger Google Analytics og Firebase Analytics, to webanalyseprogrammer leveret af Google,
                Inc ("Google") til at indsamle visse oplysninger om dit besøg på vores hjemmeside, www.pubq.se. Disse
                oplysninger kan omfatte: detaljer om dit besøg på hjemmesiden, placering, sider, du besøger, og
                oplysninger om din mobile enhed og internetforbindelse, herunder IP-adresse, operativsystem og
                browser.
                <br>
                <br>
                <span style="font-weight: bold;">Cookies </span>
                <br>
                Vi bruger cookies og andre teknologier til at modtage og gemme visse typer af oplysninger, når du
                interagerer med vores Applikation via din mobile enhed. Alle, der besøger en hjemmeside eller applikation
                med cookies, skal ifølge loven (2003:389) om elektronisk kommunikation informeres om dem og bevidst
                acceptere, at de bruges. En cookie er en lille tekstfil, der gemmes på besøgendes computer. Filen indeholder
                en række tegn, der sendes til din mobile enhed, når du besøger en hjemmeside eller bruger en
                applikation. Cookies kan gemme unikke kendetegn, præferencer og anden information. Vi bruger
                cookies til at forbedre kvaliteten af vores tjenester, herunder til at gemme brugerindstillinger
                og til at spore slutbrugere.
                <br>
                <br>
                Tredjepartscookies
                <br>

                Cookies, der bruges til at indsamle oplysninger til skræddersyet indhold samt til webstatistik fra
                andre end os, der er ansvarlige for Applikationen, kan være såkaldte tredjepartscookies. Vi
                bruger Google Analytics, som bruger cookies i form af tekstfiler, der gemmes på din computer, til
                at hjælpe os med at analysere, hvordan du bruger hjemmesiden og Applikationen. Du kan få flere oplysninger om,
                hvordan Google bruger data, når du besøger vores hjemmeside, ved at besøge "How Google uses data when you use
                our partners' sites or apps", som findes på www.google.com/policies/privacy/partners/).
                <br>
                <br>
                Placeringdata
                <br>
                Vi kan også automatisk indsamle placeringdata via din mobile enhed. Disse oplysninger kan bruges til at
                forbedre kvaliteten af vores tjenester, for at lede dig til tjenester i dit område eller for at indsamle
                data om din aktivitetsniveau. Du kan aktivere og deaktivere denne funktion via indstillingerne på din
                mobile enhed.
                <br>
                <br>
                Andre datamodtagelsesteknologier
                <br>
                Applikationen kan også indsamle data via Firebase Analytics, en analyseapplikation til
                mobilenheder, til at logge computerfejl og andre ikke-dødelige fejl, der kan opstå under brug af Applikationen.
                De indsamlede oplysninger kan omfatte, hvad du gjorde på tidspunktet for fejlen eller fejlkoden, og hvilke
                søgninger du har foretaget under brugen af Applikationen. Disse data kan ikke henføres til din PubQ-konto,
                men hjælper os med at forbedre Applikationen.
                <br>
                <br>
                Samtykke til brug og mulighed for at fravælge cookies
                <br>
                Ved at bruge Applikationen giver du samtykke til brugen af cookies som beskrevet ovenfor. Der er
                mulighed for at fravælge cookies ved at slette cookies fra din harddisk, hvilket kan gøres når som
                helst. Sletning af cookies kan medføre, at dine personlige indstillinger forsvinder. Du kan også ændre
                indstillingerne i din browser, så den afviser at gemme cookies på din harddisk. Dette kan forringe
                funktionaliteten på visse hjemmesider og føre til, at visse funktioner og tjenester i Applikationen
                holder op med at fungere. Hvis din browser understøtter en såkaldt "inkognitotilstand", kan du aktivere den for
                automatisk at slette alle cookies, hver gang du lukker din browser.
                <br>
                <br>
                Ingen oplysninger fra børn under 18 år
                <br>
                Vores Applikation er ikke beregnet til brug af børn under 18 år. Ingen under 18 år må indtaste
                oplysninger i Applikationen. Vi indsamler ikke bevidst oplysninger fra nogen under 18 år. Hvis du er under
                18 år, skal du ikke bruge og ikke indtaste nogen oplysninger i Applikationen. Hvis vi bliver opmærksomme på, at vi har
                indsamlet eller modtaget oplysninger fra et barn under 18 år uden forældres samtykke, vil vi
                slette oplysningerne. Hvis du tror, ​​at vi kan have oplysninger om et barn under 18 år, ring
                +46736235298 eller giv os besked via support@pubq.se
                <br>
                <br>
                <span style="font-weight: bold;">Vi behandler dine personoplysninger til følgende formål</span>
                <br>
                At levere enheder, tjenester og information. Vi indsamler oplysninger fra dig for at
                opfylde vores kontraktlige forpligtelser over for dig og for at levere enheder og tjenester, som du
                anmoder om, når du bruger Applikationen samt at levere de oplysninger, du anmoder om fra os. Til dette
                formål bruger Vi dine oplysninger til at kontakte dig vedrørende dine ordrer og til at sende
                bestilte enheder til dig. Vi kan sende nyhedsbreve, oplysninger om enheder og vores tjenester, nye
                tilbud og/eller andet markedsføringsmateriale til dig. Vi kan også bruge dine oplysninger til
                at administrere undersøgelser, som Vi udfører for at forstå vores brugeres behov.
                <br>
                <br>
                Reklame. Vi bruger ikke din aktivitet på Applikationen til at vise annoncer for PubQ eller andre
                tredjeparter. Vi tilhører ikke nogen reklamenetværk, der bruger din webbrowsningshistorik på andre
                websteder til at vise interessebaserede annoncer, og Vi bruger ikke cookie-teknologi, der er designet
                til at spore dig efter at have forladt Applikationen.
                <br>
                Dit samtykke. Når Vi har dit samtykke, kan Vi videregive oplysninger til andre virksomheder, organisationer eller
                personer uden for Virksomheden. Du kan til enhver tid underrette os om, at du ønsker at tilbagekalde dette samtykke. I
                så fald vil der ikke forekomme yderligere behandling af dine personoplysninger.
                <br>
                <br>
                <span style="font-weight: bold;">Hvem vi deler dine oplysninger med</span>
                <br>
                For at opfylde de formål, hvortil oplysningerne er indsamlet, kan dine oplysninger blive registreret,
                opbevares, bruges og overføres til tredjepartsleverandører til os som beskrevet
                nedenfor.
                <br>
                <br>
                Tredjepartsleverandører. Vi kan videregive oplysninger til godkendte tredjepartstjenesteudbydere
                således at tjenesteudbyderen kan administrere de servere, der bruges til Applikationen, for at administrere
                ordrer, bestille og levere varer samt levere de tjenester, du anmoder os om. Vi kan dele dine
                oplysninger med tredjeparts tjenesteudbydere for at yde support via f.eks. telefon. Disse
                tjenesteudbydere vil få adgang til dine oplysninger, så de kan yde tjenester på vores vegne.
                <br>
                <br>
                Tredjeparter, der kræves af loven eller for at beskytte os og andre. Vi kan videregive oplysninger
                om dig, hvis Vi er forpligtede til at videregive oplysninger i henhold til loven eller som svar på gyldige
                påbud, såsom en retskendelse, en domstolsafgørelse eller en gyldig retsinstans.
                <br>
                <br>
                Virksomhedsoverdragelse. Vi kan videregive oplysninger til tredjeparter i forbindelse med, eller
                under forhandling om, en fusion, salg af virksomhedenets aktiver, finansiering eller erhvervelse af hele
                eller en del af vores forretning til en anden virksomhed.
                <br>
                <br>
                Tredjepart, hvis godkendelse er modtaget. Hvis der opstår muligheder for at sælge
                virksomhedens aktiver, fusionere eller opnå finansiering, kan vi videregive oplysninger til enhver potentiel køber,
                investor eller enhver anden tredjepart, hvis godkendelse er modtaget.
                <br>
                <br>
                Internationale overførsler. Dine oplysninger, herunder personoplysninger, kan overføres til – og opbevares på –
                computere, servere, brugerterminaler og cloud-baserede tjenester, der kan være placeret uden for dit
                land eller et andet land, hvor databeskyttelsesloven kan være anderledes end i dit land. Dine oplysninger
                kan blive videregivet til, opbevaret og behandlet af vores datterselskaber, tilknyttede selskaber og
                tredjepartstjenesteudbydere i disse lande, som kan behandle dine oplysninger på vores vegne og
                i overensstemmelse med denne politik. Derudover kan dine oplysninger blive videregivet til andre
                lande, hvis det er nødvendigt for udførelsen af en kontrakt med dig, til beskyttelse af vores interesser,
                baseret på dit samtykke eller hvis loven kræver det.
                <br>
                <br>
                Hvordan vi beskytter dine oplysninger
                <br>
                Vi har implementeret passende tekniske og organisatoriske foranstaltninger til at beskytte sikkerheden
                af dine oplysninger. Vi overholder industrien standarder og anvender passende sikkerhedsmekanismer for at
                beskytte dine oplysninger mod uautoriseret adgang, ændring, afsløring eller ødelæggelse.
                <br>
                <br>
                Opbevaring af dine oplysninger
                <br>
                Vi opbevarer kun dine personoplysninger, så længe det er nødvendigt for at opfylde de formål, hvortil
                oplysningerne blev indsamlet, og/eller for at opfylde lovgivningsmæssige krav. Når vi ikke længere har brug
                for dine oplysninger, vil vi slette dem fra vores systemer og arkiver.
                <br>
                <br>
                Dine rettigheder
                <br>
                Du har ret til at anmode om adgang til og rettelse eller sletning af dine personoplysninger. Du har
                også ret til at begrænse eller gøre indsigelse mod behandlingen af dine oplysninger samt ret til
                dataportabilitet. Du har ret til at tilbagekalde eventuelle samtykker, du har givet os til
                behandling af dine oplysninger. Kontakt os venligst, hvis du ønsker at udøve disse rettigheder. Vi vil
                svare på din anmodning i overensstemmelse med gældende lovgivning.
                <br>
                <br>
                Dine muligheder
                <br>
                Du kan vælge ikke at give os personoplysninger. I så fald kan du muligvis ikke bruge visse funktioner på
                Applikationen. Du kan fravælge visse typer kommunikation, f.eks. nyhedsbreve, ved at følge
                anvisningerne i kommunikationen. Hvis du ikke ønsker at modtage markedsføringsmateriale fra os, skal du
                fravælge det ved at kontakte os på de kontaktoplysninger, der er angivet nedenfor.
                <br>
                <br>
                Ændringer i denne politik
                <br>
                Vi forbeholder os ret til at ændre denne politik med jævne mellemrum. Vi vil give dig besked om
                ændringer i politikken ved at offentliggøre den reviderede politik på vores hjemmeside. Vi anbefaler, at du
                gennemgår denne politik regelmæssigt for at holde dig informeret om, hvordan Vi behandler dine
                oplysninger.
                <br>
                <br>
                Kontaktoplysninger
                <br>
                Hvis du har spørgsmål eller kommentarer til denne politik eller ønsker at udøve dine rettigheder i
                henhold til denne politik, bedes du kontakte os på:
                <br>
                <br>
                Telefon: +46736235298
                <br>
                E-mail: support@pubq.se
                <br>
                Adresse: Butängsgatan 32, 605 96 Norrköping
            </div>
        </div>
    </div>
</section>

