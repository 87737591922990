<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{title}}</h2>
            
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" [innerHtml]="termsString">
            </div>



        </div>
    </div>
</section>