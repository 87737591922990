import { Location } from '@angular/common';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  public company = "PubQ";
  public companyNr = "559057-4843";
  public language = "se";
  public termsString = this.getSwedish();
  public title ="Licensavtal för slutanvändare";
  constructor(private activatedRoute: ActivatedRoute) {

    let params = this.activatedRoute.snapshot.queryParams;
    if (params.c && params.nr) {
      this.company = params.c;
      this.companyNr = params.nr;
    }
    this.termsString = this.getSwedish();
    if (params.lang) {
      switch (params.lang) {
        case "en": {
          this.termsString = this.getEnglish();
          this.title = "End user agreement";
          break;
        }
        default:
          this.termsString = this.getSwedish();


      }
    }
    this.language = params.lang;
  }

  ngOnInit() {

  }
  getSwedish() {
    return `
    <span style="font-weight: bold;">Mellan ${this.company}, ${this.companyNr}, (”Bolaget”) och slutanvändare</span>
    <br>
    <br>
    Detta licensavtal (”Avtal”) är ett bindande avtal mellan dig som slutanvändare (”du”, ” din” eller ”
    Slutanvändare”) av mjukvaruapplikationen (” Applikationen”) och Bolaget. Detta Avtal reglerar
    användningen av PubQs-tjänster så som de definieras nedan och all relaterad dokumentation.
    PubQs-tjänster är licensierade, och säljs inte, till Slutanvändaren.
    <br>
    <br>
    Genom att ladda ner/installera eller använda Applikationen bekräftar du att du: (a) har läst och
    förstått detta Avtal; (b) är 18 år eller äldre; och (c) godkänner innehållet i Avtalet och att dess
    innehåll är bindande för dig. Om du inte godkänner dessa villkor ska du inte ladda ner, installera eller
    använda Applikationen och du ska radera den från din Mobilenhet.
    <br>
    <br>
    PubQ-tjänsterna (”Tjänsterna”) innehåller en molnbaserad applikations- och serviceplattform för
    beställnings- och betaltjänster avsedd för transaktioner av mindre belopp som gör det möjligt för
    Slutanvändaren att genomföra beställning och betalning med hjälp av sina mobila enheter. Slutanvändarens
    användning av Tjänsterna styrs även av Slutanvändarens avtal med dennes bank eller
    bank-/kreditkortsutgivare samt andra företag vars betalmedel anslutits till Tjänsterna.
    <br>
    <br>
    <span style="font-weight: bold;">Definitioner</span>
    <br>
    ”Applikationen” betyder den nedladdningsbara mjukvara som Bolaget erbjuder och genom vilket
    Slutanvändarens konto hos Bolaget tillgängliggörs.
    <br>
    <br>
    ”Avidentifierad” betyder data som har bearbetats för att ta bort, dölja, kryptera, anonymisera eller
    aggregera identifierande information så att det inte, med rimlig ansträngning, går att hänföra sådan
    information tillbaka till dig.
    <br>
    <br>
    ”Bolaget” betyder ${this.company}, ${this.companyNr}.
    <br>
    <br>
    ”Bolagsdata” betyder all information, inklusive men inte begränsat till data, analyser, mätningar,
    rapporter, trender och mätvärden, genererade, härledda, baserade på eller relaterade till
    Slutanvändardata.
    <br>
    <br>
    ”PubQ” betyder mjukvaran och plattformen som du kan komma åt via Applikationen och ditt konto som
    tillhandahålls och underhålls av eller på uppdrag av Bolaget.
    <br>
    <br>
    ”Handlare” betyder en juridisk person som tillhandahåller varor eller tjänster och som genom avtal med
    Bolaget tar emot beställning och betalning via Tjänsterna.
    <br>
    <br>
    ”Mobilenhet” betyder en smartphone, en surfplatta eller annan mobil datoranordning där Applikationen kan
    installeras eller är installerad.
    <br>
    <br>
    ”Slutanvändardata” betyder data som du har angett eller laddat upp till Tjänsterna.
    <br>
    <br>
    ”Tjänsterna” betyder de mobila beställnings- och betaltjänsterna, inbegripet PubQ-tjänsterna och alla
    tillhörande tjänster som tillhandahålls av Bolaget.
    <br>
    <br>
    <span style="font-weight: bold;">Beviljande av Licens</span>
    <br>
    <br>
    Bolaget tillhandahåller Tjänsterna till dig som Slutanvändare genom Applikationen. Under förutsättning
    att du följer villkoren i detta Avtal beviljar Bolaget dig en personlig, begränsad, uppsägningsbar,
    icke-exklusiv, icke-överförbar licens att;

    ladda ner, installera och använda Applikationen för ditt personliga, icke-kommersiella ändamål på din
    Mobilenhet i enlighet med Applikationens dokumentation; och
    få tillgång till användning av Tjänsterna som är tillgängliga genom Applikationen på Mobilenhet i
    enlighet med detta Avtal.
    begränsningar i licensen
    Som Slutanvändare får du inte:

    kopiera Applikationen;
    ändra, översätta, anpassa eller på annat sätt utarbeta härledda verk eller förbättringar av
    Applikationen;
    demontera, dekompilera, avkoda (s.k. reverse engineer) eller på annat sätt försöka härleda eller få
    tillgång till källkoden för Applikationen, Tjänsterna eller någon del därav;
    ta bort, ändra eller dölja varumärken eller upphovsrätt, patent eller andra immateriella rättigheter
    från Applikationen eller Tjänsterna;
    hyra, låna, sälja, vidarelicensiera, tilldela, distribuera, publicera, offentliggöra eller visa,
    överföra eller på annat sätt tillgängliggöra Applikationen, Tjänsterna eller någon annan funktion eller
    funktionalitet av Applikationen eller Tjänsterna till någon tredje part, inklusive genom att
    tillgängliggöra Applikationen eller Tjänsterna på ett nätverk där den vid något tillfälle kan nås av mer
    än en enhet;
    ta bort, inaktivera, eller annars på något sätt kringgå det kopieringsskydd, system för förvaltning av
    rättigheter (DRM-system) eller säkerhetsfunktioner i eller som skyddar Applikationen; eller,
    ladda upp eller införa virus, trojaner, maskar, spionprogram, sabotageprogram, time-bombs eller andra
    skadliga komponenter till Tjänsterna.
    Du är ansvarig för att hålla inloggningsuppgifter (användarnamn och lösenord) konfidentiella och att
    inte dela dem med obehöriga användare. Om du avslöjar dina inloggningsuppgifter till någon, är du är
    ansvarig för all användning av Tjänsterna samt alla tillägg, raderingar och ändringar av din
    Slutanvändardata.
    <br>
    <br>
    <span style="font-weight: bold;">Förbehåll för immateriella rättigheter</span>
    <br>
    Du godkänner att Applikationen tillhandahålls under licens och att den inte har sålts till dig. Du
    förvärvar inte någon ägarandel i Applikationen under detta Avtal eller andra rättigheter till
    Applikationen än en rätt att använda Applikationen i enlighet med den licens som beviljas förbehållet
    alla villkor och begränsningar enligt detta Avtal. Bolaget och dess licensgivare och tjänsteleverantörer
    förbehåller var och en deras alla rättigheter till Applikationen och Tjänsterna, inbegripet men inte
    begränsat till mjukvara, varumärken och upphovsrätt. Alla rättigheter som inte uttryckligen ges i detta
    Avtal förbehålls av Bolaget och/eller dess tjänsteleverantörer. Bolaget äger alla rättigheter till
    funktionalitet, funktioner, modifiering eller anpassning av Applikationen.
    <br>
    <br>
    <span style="font-weight: bold;">Betalning</span>
    <br>
    Genom att registrera ett bank-/kreditkort eller annat betalmedel kopplat till Tjänsterna ger du Bolaget
    rätt att debitera kortet när du godkänner en transaktion eller när den automatiska betalningen utförs.
    Du ansvarar för att förse Bolaget med korrekt och aktuell information avseende registrerat kort.
    <br>
    <br>
    <span style="font-weight: bold;">Transaktioner</span>
    <br>
    Du är ansvarig för alla transaktioner som görs och godkänns i Applikationen via ditt konto. Genom att
    godkänna en transaktion samtycker du till att aktuellt belopp debiteras Bolaget. Du är vidare ansvarig
    för att det finns täckning på det registrerade kortet och ingen kreditgräns överskrids när du godkänner
    transaktionen.
    <br>
    Utlämning av varor genom något av de leveranssätt som Tjänsten stödjer sker beroende på vilket val Du gjort vid betalningstillfället. Dessa leveranssätt kan innebära att du måste hämta vid disk, få det serverat till bordet eller utkört till den plats där du befinner dig. Alla leveranser sker samma dag som betalningen utförs
    <br>
    <br>
    När en transaktion godkänns av dig betyder det att Bolaget med omedelbar verkan får en oåterkallelig
    betalningsorder från dig. När Bolaget erhållit betalningsordern utför Bolaget transaktionen och
    transaktionsbeloppet överförs till mottagaren med omedelbar verkan efter clering av berörd bank.

    Efter Bolagets mottagande av betalningsordern är det inte möjligt för dig eller Bolaget att återkalla
    transaktionen. En eventuell begäran om att återkalla transaktionen ska därför riktas direkt till
    Handlaren.

    Bolaget förbehåller sig rätten att neka en transaktion om Bolaget har skälig anledning att anta att
    transaktionen står i strid med detta Avtal eller svensk lag.
    <br>
    <br>
    <span style="font-weight: bold;">Klagomål avseende varor och tjänster
    </span>
    <br>
    Bolaget är ansvarig gentemot Slutanvändaren för eventuella fel, förseningar och andra förhållanden
    avseende den köpta varan eller tjänsten. Klagomål och krav (inklusive krav på återbetalning) ska riktas
    mot Bolaget.
    <br>
    <br>
    <span style="font-weight: bold;">Återbetalning från handlare</span>
    <br>
    Återbetalning ska alltid ske från Bolaget. Inga krav på återbetalning får riktas direkt mot Handlaren.
    Kreditering eller återbetalning ska ske snarast möjligt.
    <br>
    <br>
    <span style="font-weight: bold;">Betalningsansvar vid obehöriga transaktioner</span>
    <br>
    Om en obehörig transaktion har genomförts till följd av att Slutanvändaren har underlåtit att skydda
    sitt lösenord eller Tjänsterna inte har använts i enlighet med Avtalet ansvarar Slutanvändaren för hela
    transaktionsbeloppet.

    Slutanvändaren är inte ansvarig för belopp till följd av en obehörig transaktion som utförts efter det
    att spärranmälan avseende Tjänsterna gjorts till Bolaget. Detta gäller dock inte om Slutanvändaren har
    bidragit till den obehöriga transaktionen genom svikligt handlande. Slutanvändaren ansvarar vidare för
    hela det belopp som belastar Bolaget om Slutanvändaren underlåtit att underrätta Bolaget utan onödigt
    dröjsmål efter att Slutanvändaren fått vetskap om den obehöriga transaktionen.
    <br>
    <br>
    <br>
    Uppgifter som du tillhandahåller till oss, till exempel när du skapar eller uppdaterar ditt konto,
    registrerar dig för att använda Applikationen, begär tjänster från oss, begär information från oss,
    kontaktar kundservice eller på andra sätt kommunicerar med oss. Uppgifterna kan omfatta namn, hem- och
    leveransadress(er), e-postadress, mobiltelefonnummer, födelsedatum och kön.
    <br>
    <br>
    Insamling och användning av din information
    Du bekräftar att när du laddar ner, installerar eller använder Applikationen och/eller Tjänsterna kan
    Bolaget på automatisk väg (inklusive genom till exempel cookies och web beacons) samla in information om
    din Mobilenhet och om dig eller din användning av Applikationen (vilket ingår i definitionen av
    Slutanvändardata). Du kan också vara skyldig att ge viss information om dig själv som ett villkor för
    att ladda ner, installera eller använda Applikationen eller vissa av dess funktioner eller
    funktionalitet. Du bekräftar att du kommer att ge sanningsenlig, exakt, aktuell och fullständig
    information om dig själv och att du kommer att meddela Bolaget om eventuella ändringar av sådan
    information om nödvändigt. Att tillhandahålla information som är osann, felaktig eller ofullständig är
    en överträdelse av villkoren i detta Avtal. All information Bolaget samlar in genom eller i samband med
    denna Applikation och din användning av den omfattas av PubQs personuppgiftspolicy, som är tillgänglig
    på www.PubQ.se och som kan uppdateras emellanåt (” Personuppgiftspolicy”). Genom att ladda ner,
    installera, använda och tillhandahålla information till eller genom denna Applikation, ger du samtycke
    till all användning och de åtgärder som Bolaget vidtar med din information enligt Personuppgiftspolicyn.
    <br><br>
    Äganderätt och användning av data
    All äganderätt i och till Applikationen ska uteslutande kvarstå hos Bolaget och dess licensgivare, i
    respektive fall. Detta Avtal ger inte rätt till någon underförstådd licens eller rätt som inte
    uttryckligen anges i detta Avtal.
    <br><br>
    Slutanvändardata. I samband med tillhandahållandet av Applikationen kan Bolaget samla in och lagra
    Slutanvändardata som lämnats av dig. Du äger all Slutanvändardata. Bolaget ska säkerställa att
    Slutanvändardata som är personuppgifter sparas konfidentiellt, i enlighet med Personuppgiftspolicyn.
    Alla tredjeparter godkända av Bolaget som har tillgång till Slutanvändardata ska vara bundna av
    sekretess och lagra Slutanvändardata konfidentiellt i enlighet med Personuppgiftspolicyn. Du godkänner
    och ger härmed Bolaget rätt att behandla din Slutanvändardata som är personuppgifter i enlighet med
    Personuppgiftspolicyn.
    <br><br>
    Bolagsdata. All Bolagsdata, som inte är att anse som personuppgifter enligt vid var tid gällande
    lagstiftning för behandling av personuppgifter, är Bolagets egendom och Bolaget har rätt att använda,
    samla, lagra, skapa, aggregera, analysera, ändra, kommersialisera, tilldela, förmedla, sälja, eller
    överföra Bolagsdata i vilken form som helst, för vilka ändamål som helst och på vilket sätt som helst.
    All Bolagsdata som är att anses som personuppgifter omfattas av de förbehåll och begränsningar som anges
    i Bolagets Personuppgiftspolicy och andra kommersiella avtal som Bolaget är part till.
    <br><br>
    Uppdateringar
    Bolaget kan välja att utveckla och tillhandahålla uppdateringar till Applikationen som kan innehålla
    uppgraderingar, buggfixar och andra felkorrigeringar och/eller nya funktioner (gemensamt, inklusive
    relaterad dokumentation, ” Uppdateringar”). Uppdateringar kan också ändra eller ta bort viss
    funktionalitet och/eller vissa funktioner i sin helhet. Du godkänner att Bolaget inte har någon
    skyldighet att tillhandahålla Uppdateringar eller fortsätta att tillhandahålla eller aktivera särskilda
    funktioner eller viss funktionalitet i Applikationen. Baserat på din Mobilenhets inställningar kommer
    den, när Mobilenheten är ansluten till Internet, antingen att:
    <br><br>
    automatiskt hämta och installera alla tillgängliga uppdateringar; eller
    meddela dig om tillgängliga uppdateringar eller uppmana dig att hämta och installera tillgängliga
    uppdateringar.
    För att kunna utnyttja programmet fullt ut måste du omedelbart ladda ner och installera alla
    Uppdateringar. Du vidkänner att Applikationen eller delar av Applikationen kanske inte fungerar korrekt
    om du inte laddar ner de senaste Uppdateringarna. Du godkänner vidare att alla Uppdateringar ska anses
    vara en del av Applikationen och omfattas av alla villkor i detta Avtal.
    <br><br>
    Tredjepartsmaterial
    Applikationen kan visa, inkludera eller göra tillgängligt tredjepartsmaterial (inklusive data,
    information, applikationer och andra produkter och/eller material) eller tillhandahålla länkar till
    tredjeparts webbplatser eller tjänster, inklusive genom annonsering från tredjepart (”
    Tredjepartsmaterial”). Du accepterar att Bolaget inte ansvarar för Tredjepartsmaterial, bland annat i
    fråga om dess fullständighet, aktualitet, giltighet, överensstämmelse med upphovsrätt, laglighet,
    kvalitet eller övrigt. Bolaget har inget ansvar för Tredjepartsmaterial gentemot dig eller någon annan
    person. Tredjepartsmaterial och länkar till dessa tillhandahålls enbart som en service för dig och du
    får tillgång till och använder dem helt på egen risk och med förbehåll för tredjeparters villkor.
    <br><br>
    Avtalsperiod och uppsägning
    Avtalet börjar gälla när du hämtar/installerar Applikationen eller bekräftar godkännande av Avtalet, och
    kommer att vara i kraft tills att det avslutas av dig eller Bolaget på sätt som framgår i Avtalet.
    <br><br>
    Du kan säga upp Avtalet genom att kontakta Bolaget via telefon eller e-post enligt kontaktinformationen
    i Personuppgiftspolicy eller på bolagets hemsida och begära att Bolaget avslutar ditt konto. Om du
    avslutar ditt konto kommer Bolaget inte längre att använd dina uppgifter och kommer inte att dela den
    med tredje part.
    <br><br>
    Bolaget kan när som helst säga upp detta Avtal utan föregående meddelande om Bolaget upphör att stödja
    Applikationen i allmänhet. Bolaget kan även omedelbart säga upp Avtalet med eller utan meddelande om du
    väsentligt bryter mot något av villkoren i Avtalet.
    <br><br>
    Vid uppsägning:<br>
    kommer alla dina rättigheter enligt detta Avtal att upphöra;
    måste du upphöra med all användning av Applikationen och radera alla kopior av Applikationen från din
    Mobilenhet; och
    kommer din tillgång till Bolagets tjänster, ditt konto och din Slutanvändardata att upphöra.
    Uppsägning kommer inte att begränsa någon av Bolagets rättigheter eller tillgång till rättsliga åtgärder
    enligt lag.
    <br><br>
    Ansvarsbegränsning
    Bolaget garanterar inte att Tjänsterna alltid är tillgängliga eller att det alltid går att genomföra en
    transaktion när du använder Tjänsterna. Bolaget ersätter således inte eventuella kostnader som kan
    uppkomma till följd av att du inte kunnat använda Tjänsterna eller genomföra en transaktion.
    <br><br>
    Bolaget ansvarar inte i något fall för tjänster eller åtaganden som i samband med utförandet av
    Tjänsterna tillhandahålls eller görs av Handlare, banker, kortutgivare eller annan.
    <br><br>
    Bolaget ansvarar inte för avbrott, radering av filer eller e-post, fel, defekter, virus, förseningar i
    verksamhet eller överföring eller misslyckade prestationer om Bolaget kan visa att felet orsakats av ett
    hinder utanför Bolagets kontroll.
    <br><br>
    Bolaget ansvarar inte för indirekt skada eller förlust om inte skadan eller förlusten orsakats av grov
    vårdslöshet från Bolagets sida.
    <br><br>
    Apple- applikationer
    Dessa villkor införlivar och kompletterar Apple, Incs. (” Apples”) allmänna villkor som finns
    tillgängliga på
    https://www.apple.com/legal/internet-services/itunes/us/terms.html#service, inklusive Licensavtal för
    slutanvändare av licensierade applikationer, (” Apples villkor”). Om du använder Tjänsterna genom
    Apple-applikationen är du vidare införstådd med och accepterar följande:
    <br><br>
    Vid frågor om underhåll och support avseende Apple-Applikationen ska du kontakta Bolaget och inte Apple;
    Alla anspråk hänförliga till innehavet eller användningen av Apple-Applikationen gäller gentemot Bolaget
    och inte gentemot Apple;
    För det fall att någon utomstående tredje part hävdar att innehavet eller användningen av
    Apple-Applikationen i enlighet med dessa villkor gör intrång i den tredje partens immateriella
    rättigheter har Apple inget ansvar gentemot dig i fråga om sådana anspråk.
    För det fall att Applikationen inte överensstämmer med tillämplig Apple-garanti ska du vända dig till
    Apple för eventuell återbetalning av erlagda avgifter i enlighet med Apples villkor vid tecknande av
    licensen. I övrigt, med undantag för tvingande lag, lämnar Apple inga garantier eller andra åtaganden
    med avseende på Apple-Applikationen och ansvarar inte gentemot dig för anspråk, förluster, kostnader
    eller avgifter i förhållande till Apple-Applikationen eller som ett resultat av din eller någon annans
    användning av Apple-Applikationen eller på grund av att du eller någon annan förlitat sig på något av
    Apple-Applikationens innehåll.
    <br><br>
    Gällande RÄTT
    <br><br>
    Detta Avtal regleras av och tolkas i enlighet med lagarna i Sverige utan hänsyn av lagvalsregler.
    <br><br>
    Meddelanden
    <br><br>
    Bolaget kan skicka personuppgifter, meddelanden, upplysningar, rapporter, dokument, kommunikation eller
    andra register avseende Tjänsterna (gemensamt ” Meddelanden”) till Slutanvändaren i elektronisk form.
    Bolaget kan skicka elektroniska meddelanden till den e-postadress som Slutanvändareen angivit vid
    registreringen, som sms till det telefonnummer som Slutanvändaren angivit, som meddelande på
    Applikationen eller annars via webbplatsen. Leveransen av ett meddelande från Bolaget är levererat när
    det skickas av Bolaget, oavsett om Slutanvändaren läser meddelandet när det tas emot eller om
    Slutanvändaren faktiskt får leveransen.
    <br><br>
    Fullständigt avtal
    <br><br>
    Detta Avtal utgör det fullständiga avtalet mellan Slutanvändaren och Bolaget avseende Applikationen och
    användning av Tjänsterna och ersätter alla tidigare eller nuvarande överenskommelser och avtal, såväl
    skriftliga som muntliga. Parts underlåtenhet att tillämpa villkor enligt avtalet ska inte innebära ett
    avstående från någon rättighet enligt avtalet och underlåtenheten ska inte heller medföra att parten
    förlorar rätten att senare påkalla tillämpning av det aktuella villkoret eller annat villkor i Avtalet.
    Om någon bestämmelse i detta avtal är ogiltig eller icke verkställbar ska resten av bestämmelsen ändras
    för att uppnå ett resultat som är så nära den det ursprungliga villkoret som möjligt. Övriga
    bestämmelser i detta Avtal ska fortsätta att vara fullt tillämpbara.
    <br><br>
    Priser
    <br><br>
    Alla priser i applikationen är hänvisade inklusive moms (mervärdesskatt).    
    `
  }
  getEnglish() {
    return `
    <span style="font-weight: bold;">Between ${this.company}, ${this.companyNr}, ("Company") and end users</span>
                <br>
                <br>
                This license agreement (" Agreement ") is a binding agreement between you as an end user (" you ", " your " or " End User ") of the software application ("the Application ") and the Company. This Agreement governs the use of PubQs services as defined below and all related documentation. PubQs services are licensed, and not sold, to the End User.     
                <br>
                <br>
                By downloading / installing or using the Application, you confirm that you: (a) have read and understood this Agreement; (b) are 18 years of age or older; and (c) agree to the contents of the Agreement and that its contents are binding on you. If you do not agree to these terms, do not download, install or use the Application and delete it from your Mobile Device. 
                <br>
                <br>
                The PubQ Services (the " Services ") contain a cloud-based application and service platform for ordering and payment services intended for transactions of smaller amounts that enable the End User to carry out ordering and payment using their mobile devices. The End User's use of the Services is also governed by the End User's agreement with his bank or bank / credit card issuer and other companies whose means of payment are connected to the Services. 
                <br>
                <br>
                <span style="font-weight: bold;">Definitions</span>
                <br>
                "Application " means the downloadable software that the Company offers and through which the End User's account with the Company is made available.  
                <br>
                <br>
                " Deidentified " means data that has been processed to delete, conceal, encrypt, anonymize or aggregate identifying information so that it is not possible, with reasonable effort, to attribute such information back to you.
                <br>
                <br>
                "Company" means ${this.company}, ${this.companyNr}.
                <br>
                <br>
                " Company data " means all information, including but not limited to data, analyzes, measurements, reports, trends and measurement values, generated, derived, based on or related to End User Data. 
                <br>
                <br>
                " PubQ " means the software and platform that you can access via the Application and your account provided and maintained by or on behalf of the Company. 
                <br>
                <br>
                "Merchant" means a legal person who provides goods or services and who, by agreement with the Company, receives orders and payments via the Services. 
                <br>
                <br>
                " Mobile device " means a smartphone, tablet or other mobile computer device where the Application can be installed or is installed. 
                <br>
                <br>
                " End User Data " means data that you have entered or uploaded to the Services. 
                <br>
                <br>
                " Services " means the mobile ordering and payment services, including the PubQ services and all related services provided by the Company. 
                <br>
                <br>
                <span style="font-weight: bold;">Licensing</span>
                <br>
                <br>
                The Company provides the Services to you as an End User through the Application. Provided that you comply with the terms of this Agreement, the Company grants you a personal, limited, terminable, non-exclusive, non-transferable license to; 
                download, install and use the Application for your personal, non-commercial purposes on your Mobile Device in accordance with the Application's documentation; and 
                access the use of the Services available through the Application on Mobile Device in accordance with this Agreement. 
                <br>
                <br>
                License restrictions
                <br>
                As an End User, you may not: 
                copy the application; 
                modify, translate, adapt or otherwise prepare derivative works or improvements to the Application; 
                disassemble, decompile, decode (so-called reverse engineer) or otherwise attempt to derive or access the source code for the Application, the Services or any part thereof; 
                remove, alter or conceal trademarks or copyrights, patents or other intellectual property rights from the Application or the Services; 
                rent, borrow, sell, sublicense, assign, distribute, publish, publish or display, transfer or otherwise make available the Application, Services or any other function or functionality of the Application or Services to any third party, including by making the Application or Services available on a network where at any one time it can be accessed by more than one device; 
                remove, disable, or otherwise circumvent the copy protection, rights management system (DRM system) or security features in or protecting the Application; or, 

upload or introduce viruses, trojans, worms, spyware, malware, time-bombs or other malicious components into the Services
You are responsible for keeping your login information (username and password) confidential and not sharing it with unauthorized users. If you disclose your login information to anyone, you are responsible for all use of the Services as well as any additions, deletions and changes to your End User Data. 
                <br>
                <br>
                <span style="font-weight: bold;">Subject to intellectual property rights </span>
                <br>
                You agree that the Application is provided under license and that it has not been sold to you. You do not acquire any ownership interest in the Application under this Agreement or any rights to the Application other than a right to use the Application in accordance with the license granted subject to all terms and conditions under this Agreement. The Company and its licensors and service providers each reserve all rights to the Application and the Services, including but not limited to software, trademarks and copyrights. All rights not expressly granted in this Agreement are reserved by the Company and / or its service providers. The company owns all rights to the functionality, functions, modification or adaptation of the Application. 
                <br>
                <br>
                <span style="font-weight: bold;">Payment</span>
                <br>
                By registering a bank / credit card or other means of payment linked to the Services, you give the Company the right to debit the card when you approve a transaction or when the automatic payment is made. You are responsible for providing the Company with correct and current information regarding registered cards. 
                <br>
                <br>
                <span style="font-weight: bold;">Transactions</span>
                <br>
                You are responsible for all transactions made and approved in the Application via your account. By approving a transaction, you agree that the current amount will be debited to the Company. You are also responsible for ensuring that there is coverage on the registered card and no credit limit is exceeded when you approve the transaction. 
                <br>
                Utlämning av varor genom något av de leveranssätt som Tjänsten stödjer sker beroende på vilket val Du gjort vid betalningstillfället. Dessa leveranssätt kan innebära att du måste hämta vid disk, få det serverat till bordet eller utkört till den plats där du befinner dig. Alla leveranser sker samma dag som betalningen utförs
                <br>
                <br>
                When a transaction is approved by you, it means that the Company with immediate effect receives an irrevocable payment order from you. When the Company has received the payment order, the Company executes the transaction and the transaction amount is transferred to the recipient with immediate effect after clearing by the bank concerned. 

                After the Company's receipt of the payment order, it is not possible for you or the Company to revoke the transaction. Any request to revoke the transaction must therefore be directed to the Merchant. 
                The Company reserves the right to refuse a transaction if the Company has reasonable grounds to assume that the transaction is in violation of this Agreement or Swedish law. 
                <br>
                <br>
                <span style="font-weight: bold;">Complaints regarding goods and services 
                </span>
                <br>
                The company is liable to the End User for any errors, delays and other conditions regarding the purchased product or service. Complaints and claims (including claims for repayment) shall be directed against the Company. 
                <br>
                <br>
                <span style="font-weight: bold;">Refund from merchants</span>
                <br>
                Repayment must always be made from the Company. No refund claims may be made directly to the Dealer. Credit or repayment must be made as soon as possible. 
                <br>
                <br>
                <span style="font-weight: bold;">Liability for unauthorized transactions </span>
                <br>
                If an unauthorized transaction has been carried out as a result of the End User's failure to protect his password or the Services have not been used in accordance with the Agreement, the End User is responsible for the entire transaction amount. 
                The end user is not responsible for amounts as a result of an unauthorized transaction that has been carried out after the blocking notification regarding the Services has been made to the Company. However, this does not apply if the End User has contributed to the unauthorized transaction through fraudulent conduct. The End User is also responsible for the entire amount charged to the Company if the End User has failed to notify the Company without undue delay after the End User has become aware of the unauthorized transaction. 
                <br>
                <br>
                <br>
                Collection and use of your information 
                <br>
                You confirm that when you download, install or use the Application and / or the Services, the Company may automatically (including through cookies and web beacons) collect information about your Mobile Device and about you or your use of the Application (which is included in the definition of End User Data). You may also be required to provide certain information about yourself as a condition of downloading, installing or using the Application or any of its features or functionality. You confirm that you will provide truthful, accurate, current and complete information about yourself and that you will notify the Company of any changes to such information if necessary. Providing information that is untrue, incorrect or incomplete is a violation of the terms of this Agreement. All information The Company collects through or in connection with this Application and your use of it is covered by PubQ's personal data policy, which is available at www.PubQ.se and which can be updated from time to time (“ Personal data policy ”). By downloading, installing, using and providing information to or through this Application, you consent to all use and the measures that the Company takes with your information in accordance with the Personal Data Policy.  
                <br><br>
                Ownership and use of data 
                All ownership in and to the Application shall remain exclusively with the Company and its licensors, in each case. This Agreement does not grant the right to any implied license or right not expressly set forth in this Agreement. 
                <br><br>
                End User Data. 
                <br>
                 In connection with the provision of the Application, the Company may collect and store End User Data provided by you. You own all End User Data. The company must ensure that End User Data, which is personal data, is stored confidentially, in accordance with the Personal Data Policy. All third parties approved by the Company who have access to End User Data shall be bound by confidentiality and store End User Data confidentially in accordance with the Personal Data Policy. You agree and hereby grant the Company the right to process your End User Data which is personal data in accordance with the Personal Data Policy.  
                <br><br>
                All Company Data, which is not to be regarded as personal data in accordance with current legislation for the processing of personal data, is the Company's property and the Company has the right to use, collect, store, create, aggregate, analyze, change, commercialize, assign, convey, sell , or transfer Company Data in any form, for any purpose and in any way. All Company Data that is to be regarded as personal data is covered by the reservations and restrictions specified in the Company's Personal Data Policy and other commercial agreements to which the Company is a party.  
                <br><br>
                Updates
                The Company may choose to develop and provide updates to the Application that may include upgrades, bug fixes and other corrections and / or new features (collectively, including related documentation, " Updates "). Updates may also change or remove certain functionality and / or certain features in their entirety. You agree that the Company has no obligation to provide Updates or continue to provide or activate special features or certain functionality in the Application. Based on your Mobile Device's settings, when the Mobile Device is connected to the Internet, it will either:  

automatically download and install all available updates; or 

notify you of available updates or prompt you to download and install available updates. 
To take full advantage of the program, you must immediately download and install all Updates. You acknowledge that the Application or parts of the Application may not work properly if you do not download the latest Updates. You further agree that all Updates shall be deemed to be part of the Application and are subject to all terms of this Agreement. 
<br>
<br>

Third party material 
<br>
The Application may display, include or make available third party material (including data, information, applications and other products and / or materials) or provide links to third party websites or services, including through third party advertising (" Third Party Material "). You accept that the Company is not responsible for Third Party Materials, including in terms of its completeness, timeliness, validity, compliance with copyright, legality, quality or otherwise. The Company has no responsibility for Third Party Materials towards you or any other person. Third party materials and links to these are provided solely as a service to you and you access and use them entirely at your own risk and subject to third party terms.  
                <br><br>
                Contract period and termination 
                <br>
                The Agreement becomes effective when you download / install the Application or confirm approval of the Agreement, and will remain in effect until terminated by you or the Company in the manner set forth in the Agreement. 
                <br><br>
                You can terminate the Agreement by contacting the Company by telephone or e-mail in accordance with the contact information in the Personal Data Policy or on the Company's website and request that the Company terminate your account. If you terminate your account, the Company will no longer use your information and will not share it with third parties. 
                <br><br>
                The Company may at any time terminate this Agreement without prior notice if the Company ceases to support the Application in general. The Company may also immediately terminate the Agreement with or without notice if you materially violate any of the terms of the Agreement. 
                <br><br>
                Upon termination:<br>
                all your rights under this Agreement will cease; 

you must stop all use of the Application and delete all copies of the Application from your Mobile Device; and 

your access to the Company's services, your account and your End User Data will cease. 

Termination will not restrict any of the Company's rights or access to legal action under law. 
                <br><br>
                Limitation of liability 
                The Company does not guarantee that the Services are always available or that it is always possible to complete a transaction when you use the Services. The Company thus does not reimburse any costs that may arise as a result of you not being able to use the Services or complete a transaction. 
                <br><br>
                The company is not responsible in any case for services or commitments that in connection with the performance of the Services are provided or made by Merchants, banks, card issuers or others. 
                <br><br>
                The Company is not responsible for interruptions, deletion of files or e-mails, errors, defects, viruses, delays in operations or transmission or failed performances if the Company can show that the error was caused by an obstacle beyond the Company's control. 
                <br><br>
                The Company is not liable for indirect damage or loss unless the damage or loss was caused by gross negligence on the part of the Company. 
                <br><br>
                Apple applications 
                These Terms incorporate and supplement Apple, Inc. (" Apple ") Terms and Conditions available at https://www.apple.com/legal/internet-services/itunes/us/terms.html#service , including License Agreement for End Users of Licensed Applications, (" Apple's Terms " ) ). If you use the Services through the Apple application, you further agree to and accept the following: 
                <br><br>
                For questions about maintenance and support regarding the Apple Application, please contact the Company and not Apple; 

                All claims relating to the possession or use of the Apple Application apply to the Company and not to Apple; 
                
                In the event that any outside third party claims that the possession or use of the Apple Application in accordance with these terms infringes the intellectual property rights of the third party, Apple has no liability to you in respect of such claims. 
                
                In the event that the Application does not comply with the applicable Apple warranty, you should contact Apple for any refund of fees paid in accordance with Apple's terms when signing the license. Otherwise, except as required by law, Apple makes no warranties or other representations with respect to the Apple Application and is not liable to you for any claims, losses, costs or charges in relation to the Apple Application or as a result of your or anyone else's use of the Apple Application or because you or anyone else has relied on any of the contents of the Apple Application. 
                <br><br>
                Regarding law 
                <br><br>
                This Agreement is governed by and construed in accordance with the laws of Sweden without regard to conflict of laws rules. 
                <br><br>
                Messages
                <br><br>
                The Company may send personal information, messages, information, reports, documents, communications or other registers regarding the Services (collectively " Messages ") to the End User in electronic form. The company may send electronic messages to the e-mail address specified by the End User at the time of registration, as an SMS to the telephone number specified by the End User, as a message on the Application or otherwise via the website. The delivery of a message from the Company is delivered when it is sent by the Company, regardless of whether the End User reads the message when it is received or if the End User actually receives the delivery.  
                <br><br>
                Complete agreement 
                <br><br>
                This Agreement constitutes the entire agreement between the End User and the Company regarding the Application and use of the Services and supersedes all prior or present agreements and agreements, both written and oral. The party's failure to apply conditions under the agreement shall not constitute a waiver of any right under the agreement and the failure shall not result in the party losing the right to later demand application of the relevant condition or other condition in the Agreement. If any provision of this Agreement is invalid or unenforceable, the remainder of this provision shall be amended to achieve a result that is as close to the original condition as possible. Other provisions of this Agreement shall continue to be fully applicable. 
                <br><br>
                Prices
                <br><br>
                All prices in the application are referenced including VAT (VAT). 
    `
  }
}
