import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;

    constructor(
        private router: Router,
        location: Location,
       
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url.split("?")[0];
                    console.log(this.location)
                    if (this.location == '/') {
                        this.navbarClass = 'navbar-area';
                    } else if (
                        this.location == '/policy-no' ||
                        this.location == '/policy' ||
                        this.location == '/policy-sv' ||
                        this.location == '/policy-dk' ||
                        this.location == "/om-oss/villkor" ||
                        this.location == "/villkor" ||
                        this.location == "/terms"
                    ) {
                        this.navbarClass = 'navbar-hidden';
                    } else {
                        this.navbarClass = 'navbar-area navbar-style-two';
                    }
                }
            });
    }

    ngOnInit() {
    }

}